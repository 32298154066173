import { action, observable, computed, makeAutoObservable } from "mobx";
import { Prices, PricesList, Rates } from "../models/dtoTypes";
import { RootStore } from "./rootStore";


export default class BundleStore {
    _qnt: number = 1;
    priceList: PricesList = new Prices();
    rates: Rates = {};

    rootStore: RootStore | undefined;

    constructor(rootStore: RootStore, qnt: number) {
        this.rootStore = rootStore;
        this.setQnt(qnt);
        this.increaseQnt = this.increaseQnt.bind(this);
        this.decreaseQnt = this.decreaseQnt.bind(this);
        makeAutoObservable(this);
    }

    @action setPricesAndRates(priceList: PricesList, rates: Rates) {
        this.priceList = priceList;
        this.rates = rates;
    }

    @computed get BundlePrice(): number {
        const priceListLength = Object.keys(this.priceList).length;
        if (this._qnt <= priceListLength) {
            return this.priceList[this._qnt] * this._qnt;
        }
        else {
            return this.priceList[priceListLength] * this._qnt
        }

    }
    //computed property for the price based on the quantity of the bundle as string with 2 decimal places
    @computed get BundlePriceString(): string { return this.BundlePrice.toFixed(2); }

    @action
    setQnt(value: number) {
        if (value < 1) {
            value = 1;
        }
        else if (value > 500) {
            value = 500;
        }
        this._qnt = value;
    }

    @computed get Qnt(): number { return this._qnt; }

    @computed get PricePerMonthString(): string {
        const months = 12;
        const priceM = this.BundlePrice / months / this.Qnt;
        return priceM.toFixed(2);
    }

    @computed get PricePerComputerString(): string {
        return (this.BundlePrice / this.Qnt).toFixed(2);
    }

    @computed get Saving(): number {
        return this.priceList[1] * this.Qnt - this.BundlePrice;
    }

    @computed get SavingString(): string {
        return this.Saving.toFixed(2);
    }


    @computed get USDPrice(): number { return this.BundlePrice * this.rates["GBPUSD"]; }
    @computed get EURPrice(): number { return this.BundlePrice * this.rates["GBPEUR"]; }

    //computer property for the price in USD and EUR based on the current exchange rates as string with 2 decimal places
    @computed get USDPriceString(): string { return this.USDPrice.toFixed(0); }
    @computed get EURPriceString(): string { return this.EURPrice.toFixed(0); }

    //increase the quantity of the bundle by 1
    @action
    increaseQnt() {
        this.setQnt(this._qnt + 1);
    }

    //decrease the quantity of the bundle by 1
    @action
    decreaseQnt() {
        this.setQnt(this._qnt - 1);
    }




}