import React, { FC, Fragment, ReactNode } from 'react';

interface ModalBaseProps {
    children: ReactNode;
    close: () => void;
    title: string;
}

const ModalBase: FC<ModalBaseProps> = ({ children, close, title }) => {
    return (
        <>
            <div className="min-h-screen fixed inset-0 bg-gray-700 bg-opacity-50 overflow-y-auto h-full w-full top-0 right-0 bottom-0 left-0"
                id="my-modal"></div>

            <div role="alert" className="fixed inset-0 flex items-center justify-center">
                <div className="relative py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400 container mx-auto w-11/12 md:w-2/3 max-w-lg">
                    <div className="m-4">
                        <h1 className="text-2xl font-bold mb-6">{title}</h1>
                        {children}
                    </div>

                    <button className="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600"
                        aria-label="close modal" role="button"
                        onClick={close}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="20" height="20" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <line x1="18" y1="6" x2="6" y2="18" />
                            <line x1="6" y1="6" x2="18" y2="18" />
                        </svg>
                    </button>
                </div>
            </div>
        </>
    );
};

export default ModalBase;