import axios from 'axios';
import { API_URL } from '../constants';
import { CheckDiscountCodeResponseDto, CountriesDto, PurchaseDto, PaidOrderDto, RenewPurchaseDto, PaidRenewOrderDto } from '../models/dtoTypes';

export const checkDiscountCode = async (discountCode: string): Promise<CheckDiscountCodeResponseDto> => {
    let dCode: string = discountCode.trim().toUpperCase();
    if (dCode.length !== 6) {
        throw new Error('Discount code must be 6 characters long');
    }
    try {
        const response = await axios.get(API_URL + "/api/order/checkdiscountcode/" + dCode);
        return response.data as CheckDiscountCodeResponseDto;
    } catch (error) {
        console.error('Failed to check discount code:', error);
        throw error;
    }
};

export const fetchCountries = async (): Promise<CountriesDto> => {
    try {
        const response = await axios.get(API_URL + "/api/order/getcountries");
        return response.data as CountriesDto;
    } catch (error) {
        console.error('Failed to fetch countries:', error);
        throw error;
    }
};

export const purchasePost = async (purchaseDto: PurchaseDto): Promise<string> => {
    try {
        const response = await axios.post(API_URL + "/api/order/purchasewithstripe", purchaseDto, { withCredentials: true });      
        return response.data;
    } catch (error) {
        console.error('Failed to purchase:', error);
        throw error;
    }
};

export const renewPurchasePost = async (renewPurchaseDto: RenewPurchaseDto): Promise<string> => {
    try {
        const response = await axios.post(API_URL + "/api/renew/purchasewithstripe", renewPurchaseDto, { withCredentials: true });      
        return response.data.redirectUrl;
    } catch (error) {
        console.error('Failed to purchase:', error);
        throw error;
    }
};

export const checkPaymentCall = async (paymentId: number): Promise<PaidOrderDto> => {

    try {
        const response = await axios.get(API_URL + "/api/order/checkpayment/" + paymentId, { withCredentials: true });
        return response.data as PaidOrderDto;
    } catch (error) {
        console.error('Failed to check payment:', error);
        throw error;
    }
};  

export const checkRenewPaymentCall = async (paymentId: number): Promise<PaidRenewOrderDto> => {

    try {
        const response = await axios.get(API_URL + "/api/renew/checkpayment/" + paymentId, { withCredentials: true });
        return response.data as PaidRenewOrderDto;
    } catch (error) {
        console.error('Failed to check payment:', error);
        throw error;
    }
};  