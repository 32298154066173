import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import OrderStore from '../../stores/orderStore';
import AuthenticatedPage from '../auth/authenticatedPage';
import { RootStoreContext } from '../../stores/rootStore';
import { set } from 'mobx';
import loadingGif from '../../assets/images/Blocks-1s-200px.gif';
import { observer } from 'mobx-react-lite';
import { saveVisit } from '../../api/visitTrackerService';

const RenewSuccess = () => {
    const rootStore = useContext(RootStoreContext);
    const { paymentId } = useParams();
    const orderStore = rootStore.orderStore;

    // useEffect(() => {
    //     if (isLoggedIn && paymentId !== undefined) {
    //         orderStore.checkPayment(parseInt(paymentId));
    //     }
    // }, [])
    useEffect(() => {
        saveVisit('renew-success PaymentId: ' + paymentId);
        if (orderStore !== undefined) {
            if (paymentId !== undefined) {
                orderStore.checkRenewPayment(parseInt(paymentId));
            }
        }
    }, [orderStore, paymentId]);


    return (
        <>
            {orderStore.order === undefined ?
                <p className="mx-auto text-center text-lg font-semibold mt-5"><em>
                    Please wait. We are loading your order details...</em>
                    <div className="flex  items-center  justify-center">
                        <img src={loadingGif} />
                    </div>
                </p>
                :
                <div className="flex justify-center">
                    <div className="text-left">

                        {orderStore.order?.error !== null ?
                            <>
                                <p className="mx-auto text-center text-lg font-semibold mt-5">
                                    <em> Sorry there was an error while checking your order</em>
                                </p>
                                <p className="mx-auto text-center text-lg font-semibold mt-5">
                                    <em> {orderStore.order?.error}</em>
                                </p>
                            </>
                            :
                            <>
                                <h3 className="mx-auto text-center text-2xl font-semibold mt-5 mb-5">Thank you for renewing your subscription for Desktop Call Recorder! </h3>

                                <div className="text-left">
                                    <p className="px-2 my-1" >Your subscription has been successfully extended and is now valid until <span className='font-bold'>{orderStore.order?.subscribtionEndDate}</span>.</p>
                                    <p className="px-2 my-1">All your existing activation codes remain the same, so there’s no need to update or reinstall anything.</p>
                                    <p className="px-2 my-4">To view your subscription details or access your activation codes, visit your 
                                        <a className="underline text-indigo-600 hover:text-indigo-800" href="/subscriptions"> Subscription Page.</a>
                                    </p >
                                    <p className="px-2 my-1">We’re thrilled to have you with us for another year! 
                                        If you have any questions or need assistance, feel free to reach out to our  <a className="underline text-indigo-600 hover:text-indigo-800"
                                        href="mailto:team@desktopcallrecorder.com">support team</a>.</p>

                                </div>
                            </>
                        }
                    </div>
                </div>
            }
        </>
    )
}

export default observer(RenewSuccess)