import { action, computed, makeAutoObservable, observable, runInAction } from "mobx";
import { RootStore } from "./rootStore";
import { CountryDto, InvoiceDataDto, InvoiceDetailsFormValues } from "../models/dtoTypes";
import { fetchInvoice, postInvoiceDetails } from "../api/invoiceService";
import { fetchCountries } from "../api/orderService";


export default class InvoiceStore {
   
    paymentId: number | undefined;
    @observable invoiceHtml: string | null = null;
    @observable isInvoiceModalOpen: boolean = false;
    @observable loading: boolean = false;
    @observable country: string|null = null;    
    
    // @observable name: string = '';
    // @observable address: string = '';
    // @observable buyersRefNumber: string = '';

    @computed get noInvoiceHtml() {
        return this.invoiceHtml === null;
    }

    // @computed get isInvoiceDateValid() {
    //     return this.name !== '' && this.address !== ''
    // }

    constructor( rootStore: RootStore) {
        
        makeAutoObservable(this);
        
    }
    fieldStyleNormal: string = "w-1/2 bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5";
    fieldStyleError: string = " w-1/2 bg-red-100 border border-red-600 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5";

    @observable invoiceBusinessNameTouched: boolean = false;
    @observable invoiceBusinessName: string = '';
    @action setInvoiceBusinessName(value: string) {
        this.invoiceBusinessName = value;
        this.invoiceBusinessNameTouched = true;
    }
    @computed get invoiceBusinessNameFieldStyle() {
        if (this.invoiceBusinessNameTouched && this.invoiceBusinessName.trim() === '') {
            return this.fieldStyleError;
        }
        return this.fieldStyleNormal;
    }

    @observable invoiceAddress: string = '';
    @action setInvoiceAddress(value: string) {
        this.invoiceAddress = value;
        this.invoiceAddressTouched = true;
    }
    @observable invoiceAddressTouched: boolean = false;
    @computed get invoiceAddressFieldStyle() {
        if (this.invoiceAddressTouched && this.invoiceAddress.trim() === '') {
            return this.fieldStyleError;
        }
        return this.fieldStyleNormal;
    }

    @observable invoiceRefNumber: string = '';
    @action setInvoiceRefNumber(value: string) {
        this.invoiceRefNumber = value;
    }

   

    @computed get isInvoiceDataValid() {
        if (this.invoiceBusinessName === '') {
            return false;
        }
        if (this.invoiceAddress === '') {
            return false;
        }

        return true;
    }

    @observable isSubmitClicked: boolean = false;

    @action closeInvoiceModal = () => {
        // Logic to close the invoice modal
        
        this.paymentId = undefined;
        this.invoiceHtml = null;
        this.isInvoiceModalOpen = false;
    }

    @action openInvoiceModal = (id: number | undefined, invoiceHtml: string|null, country: string|null) => {
        if (id === undefined) {
            this.closeInvoiceModal();
            return;
        }
        this.paymentId = id;
        // Logic to open the invoice modal
        this.invoiceHtml = invoiceHtml;
        this.isInvoiceModalOpen = true;
        this.country = country;

        if (this.invoiceHtml === null) {
            this.loading = true;
            // Call the API to get the invoice
            fetchInvoice(id).then((invoiceData: InvoiceDataDto) => {
                runInAction(() => {
                    this.invoiceHtml = invoiceData.invoiceHtml ?? null;
                    this.loading = false;
                });
            }).catch((error) => {
                console.error('Failed to fetch invoice:', error);
                runInAction(() => {
                    this.loading = false;
                });
            });
            this.loading = false;
        }
    }

    

    @action postInvoiceDetails = () =>
    {
        if (this.paymentId === undefined) {
            return;
        }
        if (this.isInvoiceDataValid === false) {
            return;
        }

        this.isSubmitClicked = true;

        const postInvoiceDto: InvoiceDataDto = {
            paymentId: this.paymentId ?? 0,
            name: this.invoiceBusinessName,
            address: this.invoiceAddress,
            buyersRefNumber: this.invoiceRefNumber,
        }
        this.loading = true;
        postInvoiceDetails(postInvoiceDto).then((invoiceHtml: string) => {
            runInAction(() => {
                this.loading = false;
                this.invoiceHtml = invoiceHtml ?? null;
            });
        }
        ).catch((error) => {
            this.loading = false;
            console.error('Failed to post invoice details:', error);
            this.isSubmitClicked = false;
        });
    }
}

