import React from 'react'
import { useParams } from 'react-router-dom';
import { saveVisit } from '../../api/visitTrackerService';

const PaymentCancel= () => {
    const { paymentId } = useParams();
    saveVisit('payment-cancel PaymentId: ' + paymentId);
    return (
        <>
            <div>payment canceled</div>
        </>
    )
}

export default PaymentCancel