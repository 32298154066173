import axios, { Axios } from "axios";
import { API_URL } from "../constants";
import { ICurrentUserDto, IForgotInputs, ILoginInputs, IForgotRequestDto, IResetPasswordInputs, IRegisterInputs } from "../models/dtoAuthTypes";

export const login = async (loginData: ILoginInputs): Promise<ICurrentUserDto | null> => {
  try {
    const response = await axios.post(API_URL + "/api/auth/login", loginData, { headers: { 'Content-Type': 'application/json' }, withCredentials: true });
    if ((response.status === 200) || (response.status === 204)) {
      const userInfo = await getUser();
      return userInfo;
    }
  } catch (error: any) {
    console.error("Failed to login:", error.message);
    throw error;
  }
  return null;
};

export const register = async (registerData: IRegisterInputs): Promise<ICurrentUserDto | null> => {
  try {
    const response = await axios.post(API_URL + "/api/auth/register", registerData, { headers: { 'Content-Type': 'application/json' }, withCredentials: true });
    if ((response.status === 200) || (response.status === 204)) {
      const userInfo = await getUser();
      return userInfo;
    }
  } catch (error: any) {
    console.error("Failed to register:", error.message);
    throw error;
  }
  return null;
}


export const logout = async (): Promise<boolean> => {
  try {
    const response = await axios.post(API_URL + "/api/auth/logout", {}, { headers: { 'Content-Type': 'application/json' }, withCredentials: true });
    console.log('Logout response:', response);
    if ((response.status === 200) || (response.status === 204) || (response.status === 401)) {
      console.log('Logging out');
      return true;
    }
    return response.data;
  } catch (error) {
    console.error("Failed to logout:", error);
    return false;
  }
};

export const getUser = async (): Promise<ICurrentUserDto> => {
  try {
    const response = await axios.get(API_URL + "/api/auth/currentuserinfo", { withCredentials: true });
    return response.data as ICurrentUserDto;
  } catch (error) {
    console.error("Failed to get user name:", error);
    throw error;
  }
}

export const requestResetCall = async (forgotForm: IForgotInputs): Promise<boolean> => {
  try {
    const forgotRequestData: IForgotRequestDto = {
      email: forgotForm.email,
      baseUrl: window.location.origin
    }

    const response = await axios.post(API_URL + "/api/auth/forgot", forgotRequestData); console.log('Forgot response:', response);
    if ((response.status === 200)) {
      return true;
    }
    return response.data;
  } catch (error) {
    console.error("Failed to request reset:", error);
    return false;
  }
}

export const resetPasswordCall = async (resetPasswordInputs: IResetPasswordInputs): Promise<string> => {
  try {
    const response = await axios.post(API_URL + "/api/auth/reset",
      { code: resetPasswordInputs.code, password: resetPasswordInputs.password },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      });
    console.log('Reset response:', response);
    if ((response.status === 200)) {
      return '';
    }
    return 'Failed to reset password';
  } catch (error) {
    console.error("Failed to reset password:", error);

    return 'Something went wrong. Please try again later.';
  }
}