import axios from "axios";
import { VisitDto } from "../models/visitDto";
import { API_URL } from "../constants";
import { v4 as uuidv4 } from 'uuid';

export const saveVisit = async (eventNote: string): Promise<void> => {
  try {
    const visit = await createVisitDto(eventNote);
    var visitUrl = API_URL + "/api/visits/save";
    await axios.post(visitUrl, visit);
  } 
    catch (error) {
        console.error('Failed to save visit:', error);
    }
}

const createVisitDto = async (eventNote: string): Promise<VisitDto> => {
    
    var visitDto: VisitDto = {
        visitorId: '',
        url: window.location.href,
        eventNote: eventNote
    };

    const visitorIdKey = "nid";
    const visitorId = localStorage.getItem(visitorIdKey);

    if (visitorId) {
        visitDto.visitorId = visitorId;
    } else {
        const newVisitorId = uuidv4();
        visitDto.visitorId = newVisitorId;
        localStorage.setItem(visitorIdKey, newVisitorId);
    }

    return visitDto;
};