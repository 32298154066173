import { makeAutoObservable, observable, runInAction, action,computed } from "mobx";
import axios from "axios";
import { RootStore } from "./rootStore";
import { PaymentDtoV2, SubscriptionDtoV2 } from "../models/dtoTypes";
import { fetchSubscriptions } from "../api/subscriptionService";
import UserStore from "./userStore";


export default class SubscriptionsStore {
    rootStore: RootStore | undefined;
    userStore:UserStore | undefined;
    @observable subscriptions: SubscriptionDtoV2[] = [];
    @observable loading: boolean = false;
    @observable error: string | null = null;
   

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.userStore = rootStore.userStore;
        makeAutoObservable(this);
    }

    @action fetchSubscriptions() {
        if (this.loading) return;
        this.loading = true;
        fetchSubscriptions().then((subscriptions: SubscriptionDtoV2[]) => {
            runInAction(() => {
                this.subscriptions = subscriptions;
                this.loading = false;
                this.error = null;
            });
        }).catch((error) => {
            runInAction(() => {
                this.error = error.message;
                this.loading = false;
            });
        });
    }

    @action openInvoiceModal(payment:PaymentDtoV2) {
        this.rootStore?.invoiceStore.openInvoiceModal(payment.id, payment.invoiceHtml ?? null, payment.countryName ?? null);
    }

    
}
