import { saveVisit } from '../../api/visitTrackerService';
const NotFound = () => {

  saveVisit('404 page visited');
  return (
    <div className="flex justify-center items-center h-screen">
        <h1 className="text-4xl font-bold">Sorry, this page doesn't exist</h1>
    </div>
  )
}

export default NotFound