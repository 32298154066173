import React from 'react';
import Header from './header';
import Home from '../pages/home';
import ModalAuth from '../auth/modal-auth';
import { Outlet, ScrollRestoration, useLocation } from 'react-router-dom';


function App() {
  const location = useLocation();
  return (
    <>
      {/* <ScrollRestoration /> */}
      <div className="App">
        <Header />
        {location.pathname === '/' ? <Home /> : (
          <Outlet />
        )}
        <ModalAuth />
      </div>
    </>

  );
}



export default App;
