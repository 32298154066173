import React, { useContext, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { RootStore, RootStoreContext } from '../../stores/rootStore'
import ModalBase from '../layout/modal-base';
import { IForgotInputs, ILoginInputs, IRegisterInputs } from '../../models/dtoAuthTypes';
import { Form, Field, FieldRenderProps } from 'react-final-form';
import TextInput from '../forms/TextInput';
import { validateForgotForm, validateLoginRegisterForm } from '../forms/Validation';
import spinner from '../../assets/images/Spinner-1s-55px.gif';


const ModalAuth: React.FC = () => {

    const rootStore: RootStore = useContext(RootStoreContext);

    const { isLoginFormOpened, OpenLoginForm, CloseLoginForm, Login, loginError } = rootStore.userStore;
    const { isForgotFormOpened, OpenForgotForm, CloseForgotForm, RequestReset, forgotError } = rootStore.userStore;
    const { isRegisterFormOpened, OpenRegisterForm, CloseRegisterForm, Register, registerError } = rootStore.userStore;
    const { isLoggingIn: isConnecting } = rootStore.userStore;

    const handleLoginFormSubmit = async (values: ILoginInputs) => {
        Login(values);
    };

    const handleForgotFormSubmit = async (values: IForgotInputs) => {
        console.log('Final form values:', values);
        await RequestReset(values);
    };

    const handleRegisterFormSubmit = async (values: IRegisterInputs) => {
        Register(values);
    };


    return (
        <>
            {isLoginFormOpened && (
                <ModalBase close={CloseLoginForm} title='Sign in'>
                    <Form
                        onSubmit={handleLoginFormSubmit}
                        validate={validateLoginRegisterForm}
                        render={({ handleSubmit, submitting, invalid }) => (
                            <form onSubmit={handleSubmit}>
                                {loginError !== '' &&
                                    <div>
                                        <label className="text-red-500 italic mb-2">{loginError}</label>
                                    </div>
                                }
                                <label className="text-gray-800 text-sm font-bold leading-tight tracking-normal">Email</label>
                                <Field
                                    name="email"
                                    component={TextInput as React.ComponentType<FieldRenderProps<string, HTMLElement, string>>}
                                    type="text"
                                    placeholder="Email"
                                />
                                <label className="text-gray-800 text-sm font-bold leading-tight tracking-normal">Password</label>
                                <Field
                                    name="password"
                                    component={TextInput as React.ComponentType<FieldRenderProps<string, HTMLElement, string>>}
                                    type="password"
                                    placeholder="Password"
                                />
                                <Field
                                    name="remeberMe"
                                    component={({ input }: FieldRenderProps<boolean, HTMLElement>) => (
                                        <div className="flex items-center justify-start w-full mt-10">
                                            <input {...input} type="checkbox" className="mr-2" value={input.value ? 'true' : 'false'} />
                                            <label className="text-sm text-gray-600">Remember me</label>
                                        </div>
                                    )}
                                    type="checkbox"
                                />

                                <div className="mt-5">
                                    <div className="flex items-center justify-start w-full mt-10">
                                        {isConnecting ? (
                                            <div className="flex  items-center  justify-center">
                                                <img src={spinner} alt="Loading..." />
                                            </div>
                                        ) : (
                                            <>
                                                <button
                                                    type="submit"
                                                    className="rounded hover:bg-blue-400 bg-blue-500 text-white px-8 py-2 text-sm"
                                                    disabled={submitting || invalid}>
                                                    Sign in
                                                </button>
                                                <button className="focus:outline-none focus:ring-2 focus:ring-offset-2  focus:ring-gray-400 ml-3 bg-gray-100 transition duration-150 text-gray-600 ease-in-out hover:border-gray-400 hover:bg-gray-300 border rounded px-8 py-2 text-sm"
                                                    onClick={CloseLoginForm}>
                                                    Cancel
                                                </button>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </form>
                        )} />

                    <div className="text-end mt-4 ">
                        <a className="text-sm text-blue-500 hover:text-blue-700 underline cursor-pointer"
                            href="" onClick={(e) => {
                                e.preventDefault();
                                OpenForgotForm();
                            }}>
                            Forgot password?
                        </a>
                        <br />
                        
                    </div>
                </ModalBase>
            )}

            {isForgotFormOpened && (
                <>
                    <ModalBase close={CloseForgotForm} title='Forgot password'>
                        <Form
                            onSubmit={handleForgotFormSubmit}
                            validate={validateForgotForm}
                            render={({ handleSubmit, invalid, submitting }) => (
                                <form onSubmit={handleSubmit}>
                                    {forgotError !== '' &&
                                        <div>
                                            <label className="text-red-500 italic mb-2">{forgotError}</label>
                                        </div>
                                    }
                                    <label className="text-gray-800 text-sm font-bold leading-tight tracking-normal">Email</label>
                                    <Field
                                        name="email"
                                        component={TextInput as React.ComponentType<FieldRenderProps<string, HTMLElement, string>>}
                                        type="text"
                                        placeholder="Email"
                                    />
                                    <div className="mt-5">

                                        <div className="flex items-center justify-start w-full mt-10">

                                            {isConnecting ? (
                                                <img src={spinner} alt="Loading..." />
                                            ) : (
                                                <>
                                                    <button type="submit" className="rounded hover:bg-blue-400 bg-blue-500  text-white px-8 py-2 text-sm"
                                                        disabled={submitting || invalid}>
                                                        Request reset
                                                    </button>
                                                    <button className="focus:outline-none focus:ring-2 focus:ring-offset-2  focus:ring-gray-400 ml-3 bg-gray-100 transition duration-150 text-gray-600 ease-in-out hover:border-gray-400 hover:bg-gray-300 border rounded px-8 py-2 text-sm"
                                                        onClick={CloseForgotForm}>Cancel</button>
                                                </>
                                            )}
                                        </div>

                                    </div>
                                </form>
                            )} />
                        <div className="text-end mt-4 ">
                            <a className="text-sm text-blue-500 hover:text-blue-700 underline cursor-pointer"
                                href="" onClick={(e) => {
                                    e.preventDefault();
                                    OpenLoginForm();
                                }}>
                                Back to login
                            </a>
                        </div>
                    </ModalBase>
                </>
            )}

            {isRegisterFormOpened && (
                <ModalBase close={CloseLoginForm} title='Register new user'>
                    <Form
                        onSubmit={handleRegisterFormSubmit}
                        validate={validateLoginRegisterForm}
                        render={({ handleSubmit, submitting, invalid }) => (
                            <form onSubmit={handleSubmit}>
                                {registerError !== '' &&
                                    <div>
                                        <label className="text-red-500 italic mb-2">{registerError}</label>
                                    </div>
                                }
                                <label className="text-gray-800 text-sm font-bold leading-tight tracking-normal">Email</label>
                                <Field
                                    name="email"
                                    component={TextInput as React.ComponentType<FieldRenderProps<string, HTMLElement, string>>}
                                    type="text"
                                    placeholder="Email"
                                />
                                <label className="text-gray-800 text-sm font-bold leading-tight tracking-normal">Password</label>
                                <Field
                                    name="password"
                                    component={TextInput as React.ComponentType<FieldRenderProps<string, HTMLElement, string>>}
                                    type="password"
                                    placeholder="Password"
                                />


                                <div className="mt-5">
                                    <div className="flex items-center justify-start w-full mt-10">
                                        {isConnecting ? (
                                            <div className="flex  items-center  justify-center">
                                                <img src={spinner} alt="Loading..." />
                                            </div>
                                        ) : (
                                            <>
                                                <button
                                                    type="submit"
                                                    className="rounded hover:bg-blue-400 bg-blue-500 text-white px-8 py-2 text-sm"
                                                    disabled={submitting || invalid}>
                                                    Create account
                                                </button>
                                                <button className="focus:outline-none focus:ring-2 focus:ring-offset-2  focus:ring-gray-400 ml-3 bg-gray-100 transition duration-150 text-gray-600 ease-in-out hover:border-gray-400 hover:bg-gray-300 border rounded px-8 py-2 text-sm"
                                                    onClick={CloseRegisterForm}>
                                                    Cancel
                                                </button>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </form>
                        )} />

                    <div className="text-end mt-4 ">
                        <a className="text-sm text-blue-500 hover:text-blue-700 underline cursor-pointer"
                            href="" onClick={(e) => {
                                e.preventDefault();
                                OpenLoginForm();
                            }}>
                            Already have account?
                        </a>
                    </div>
                </ModalBase>
            )}
        </>
    );
}

export default observer(ModalAuth)