import React from 'react';
import {FieldRenderProps } from 'react-final-form';

interface ITextInputProps extends FieldRenderProps<string, HTMLInputElement> { }


const TextInput: React.FC<ITextInputProps> = ({ input, type, meta, placeholder }) => {
  return (
    <div className="mb-5">
      {/* <pre>{JSON.stringify(input, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(meta, null, 2)}</pre> */}
      <input {...input} placeholder={placeholder} 
          className="mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
         />
        {meta.touched && meta.error && <span className='ml-1 text-red-500'>{meta.error}</span>}
    </div >
  );
};

export default TextInput;
