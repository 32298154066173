import { action, observable, computed, makeAutoObservable, runInAction } from "mobx";
import { RootStore } from "./rootStore";
import { CountriesDto, CountryDto, PricesList, Rates, RenewPurchaseDto } from "../models/dtoTypes";
import { checkDiscountCode, fetchCountries, purchasePost, renewPurchasePost } from "../api/orderService";
import { fetchRenewal, fetchRenewalByActivationCodeEncoded } from "../api/subscriptionService";
import { root } from "postcss";

export default class RenewStore {
    rootStore: RootStore | undefined;

    @observable loading: boolean = false;
    @observable countries: CountryDto[] = [];
    subscriptionId: number | undefined;
    @observable isNotExpiredYet: boolean = false;
    @observable pricePerUser: number = 0;
    @observable qnt: number = 1;
    @observable renewalDiscount: number = 0;
    @observable endDate: string = '';

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    @action setSubscription(subscriptionCode: string) {
        this.loading = true;
        fetchRenewal(subscriptionCode).then((response) => {
            runInAction(() => {
                this.subscriptionId = response.subscriptionId;
                this.qnt = response.qnt;
                this.pricePerUser = response.pricePerUser;
                this.isNotExpiredYet = response.isNotExpiredYet;
                this.renewalDiscount = response.renewalDiscount;
                this.endDate = response.endDate;
            });
            return response;
        })
            .catch((error) => {
                console.error('Failed to fetch renewal:', error);
                window.location.href = '/';
            })
            .finally(() => {
                runInAction(() => {
                    this.loading = false;
                });
            });

        fetchCountries().then((response) => {
            runInAction(() => {
                if (response.detectedCountryCode !== undefined && response.detectedCountryCode !== null) {
                    this.invoiceCountryIsoCode = response.detectedCountryCode;
                } else {
                    this.invoiceCountryIsoCode = 'GB';
                }
                this.countries = response.countries;
            });
        }).catch((error) => {
            console.error('Failed to fetch countries:', error);
        });
    }

    @action setSubscriptionByActivationCode(activationCodeEncoded: string) {
        this.loading = true;
        fetchRenewalByActivationCodeEncoded(activationCodeEncoded).then((response) => {
            runInAction(() => {
                this.subscriptionId = response.subscriptionId;
                this.pricePerUser = response.pricePerUser;
                this.qnt = response.qnt;
                this.isNotExpiredYet = response.isNotExpiredYet;
                this.renewalDiscount = response.renewalDiscount;
                this.endDate = response.endDate;

            });
            return response;
        })
            .catch((error) => {
                console.error('Failed to fetch renewal:', error);
            })
            .finally(() => {
                runInAction(() => {
                    this.loading = false;
                });
            });
    }

    //#region Prices and rates

    @computed get pricePerUserString() {
        return this.pricePerUser.toFixed(2);
    }

    get PricePerMonthString(): string {
        const months = 12;
        const priceM = this.pricePerUser / months;
        return priceM.toFixed(2);
    }

    @computed get renewalDiscountString() {
        return this.renewalDiscount.toFixed(0);
    }

    @computed get earlyRenewalDiscountApplied() {
        return (this.renewalDiscount > 0) && !this.isDiscountCodeValid;
    }
    @computed get renewalDiscountAmountString() {
        return (this.total * this.renewalDiscount / 100).toFixed(2);
    }

    @computed get total() {
        return this.qnt * this.pricePerUser;
    }
    @computed get totalString() {
        return this.total.toFixed(2);
    }


    // @computed get anyDiscountApplied() {
    //     return this.totalDiscount > 0;
    // }

    @computed get discountCodeAmountString() {
        return ((this.isDiscountCodeValid ? this.discountCodePercentage : 0) * this.total / 100).toFixed(2);
    }


    @computed get totalDiscount() {
        return (this.isDiscountCodeValid ? this.discountCodePercentage : this.renewalDiscount) * this.total / 100;
    }


    @computed get totalAfterDiscount() {
        return this.total - this.totalDiscount;
    }
    @computed get totalAfterDiscountString() {
        return this.totalAfterDiscount.toFixed(2);
    }

    //computer property for the price in USD and EUR based on the current exchange rates as string with 2 decimal places
    @computed get USDPriceString(): string {
        var result: string = "";
        if (this.rootStore?.ratesLoaded) {
            result = (this.totalAfterDiscount * this.rootStore.rates["GBPUSD"]).toFixed(0);
        }
        return result;

    }
    @computed get EURPriceString(): string {
        var result: string = "";
        if (this.rootStore?.ratesLoaded) {
            result = (this.totalAfterDiscount * this.rootStore.rates["GBPEUR"]).toFixed(0);
        }
        return result;
    }
    //#endregion

    //#region Discount code
    @observable iHaveDiscount: boolean = false;
    @action setIHaveDiscount(value: boolean) {
        this.iHaveDiscount = value;
    }
    @observable discountCode: string = '';
    @observable showCheckingDiscountSpinner: boolean = false;
    @observable discountCodePercentage: number = 0;
    @computed get discountPercentageString() {
        return this.discountCodePercentage.toFixed(0);
    }

    @action setDiscountCode(value: string) {
        this.discountError = '';
        this.discountCodePercentage = 0;

        //trimming and uppercasing the discount code, then checking its length
        let dCode: string = value.trim().toUpperCase();
        this.discountCode = dCode;
        if (dCode.length !== 6) {
            this.showCheckingDiscountSpinner = false;
            return;
        }
        this.discountCode = dCode;
        this.showCheckingDiscountSpinner = true;

        checkDiscountCode(dCode).then((response) => {
            runInAction(() => {
                this.showCheckingDiscountSpinner = false;
                this.isDiscountCodeValid = response.isDiscountCodeValid;
                if (response.isDiscountCodeValid) {
                    this.discountCodePercentage = response.discountPercentage;
                }
                else {
                    this.discountCodePercentage = 0;
                }

                this.discountError = response.discountCodeError;
            });
        }).catch((error) => {
            this.showCheckingDiscountSpinner = false;
            this.discountError = error.message;
        });

    }

    @observable discountError: string = '';

    @observable isDiscountCodeValid: boolean = false;

    //#endregion



    //#region invoice properties
    @observable isInvoiceNeeded: boolean = false;
    @action setInvoiceNeeded(value: boolean) {
        this.isInvoiceNeeded = value;
    }

    @observable invoiceRefNumber: string = '';
    @action setInvoiceRefNumber(value: string) {
        this.invoiceRefNumber = value;
    }

    fieldStyleNormal: string = "w-1/2 bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5";
    fieldStyleError: string = " w-1/2 bg-red-100 border border-red-600 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5";

    @observable invoiceBusinessNameTouched: boolean = false;
    @observable invoiceBusinessName: string = '';
    @action setInvoiceBusinessName(value: string) {
        this.invoiceBusinessName = value;
        this.invoiceBusinessNameTouched = true;
    }
    @computed get invoiceBusinessNameFieldStyle() {
        if (this.invoiceBusinessNameTouched && this.invoiceBusinessName.trim() === '') {
            return this.fieldStyleError;
        }
        return this.fieldStyleNormal;
    }

    @observable invoiceAddress: string = '';
    @action setInvoiceAddress(value: string) {
        this.invoiceAddress = value;
        this.invoiceAddressTouched = true;
    }
    @observable invoiceAddressTouched: boolean = false;
    @computed get invoiceAddressFieldStyle() {
        if (this.invoiceAddressTouched && this.invoiceAddress.trim() === '') {
            return this.fieldStyleError;
        }
        return this.fieldStyleNormal;
    }

    @observable invoiceCountryIsoCodeTouched: boolean = false;
    @observable invoiceCountryIsoCode: string = '';
    @action setInvoiceCountryIsoCode(value: string) {
        this.invoiceCountryIsoCode = value;
        this.invoiceCountryIsoCodeTouched = true;
    }
    @computed get invoiceCountryIsoCodeFieldStyle() {
        if (this.invoiceCountryIsoCodeTouched && this.invoiceCountryIsoCode.trim() === '') {
            return this.fieldStyleError;
        }
        return this.fieldStyleNormal;
    }

    @computed get isInvoiceDataValid() {
        if (this.isInvoiceNeeded) {
            if (this.invoiceBusinessName === '') {
                return false;
            }
            if (this.invoiceAddress === '') {
                return false;
            }
            if (this.invoiceCountryIsoCode === '') {
                return false;
            }
        }
        return true;
    }
    //#endregion

    // proccesing order properties
    @observable isCheckoutClicked: boolean = false;
    @observable PurchaseError: string = '';
    @computed get isOrderValid() {
        if (!this.isInvoiceDataValid) {
            return false;
        }
        return this.qnt > 0;
    }

    @action HandleCheckoutClicked() {
        this.isCheckoutClicked = true;
        this.invoiceBusinessNameTouched = true;
        this.invoiceAddressTouched = true;
        this.invoiceCountryIsoCodeTouched = true;
        if (!this.isInvoiceDataValid) {
            this.isCheckoutClicked = false;
            return;
        }

        try {
            this.PurchaseError = '';
            var renewPurchaseDto: RenewPurchaseDto = {
                Url: window.location.origin,
                SubscriptionId: this.subscriptionId ? this.subscriptionId : 0,
                DiscountCode: this.iHaveDiscount ? this.discountCode : '',
                Name: this.invoiceBusinessName,
                Address: this.invoiceAddress,
                BuyersRefNumber: this.invoiceRefNumber,
                CountryIsoCode: this.invoiceCountryIsoCode,
                InvoiceNeeded: this.isInvoiceNeeded,
                BuyersVatNumber: ''
            };

            renewPurchasePost(renewPurchaseDto).then((responseUrl) => {
                runInAction(() => {
                    window.location.href = responseUrl;
                });
            }).catch((error) => {
                runInAction(() => {
                    console.error('Failed to purchase:', error);
                    this.PurchaseError = "Something went wrong: " + error.message;
                    this.isCheckoutClicked = false;
                });
            });
        } catch (error) {
            runInAction(() => {
                console.error('Failed to create purchaseDto:', error);
                this.PurchaseError = "Something went wrong: ";
                this.isCheckoutClicked = false;
            });
            return;
        }

    }
};

