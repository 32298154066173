import React, { useContext, useEffect } from 'react';
import { RootStore, RootStoreContext } from '../../stores/rootStore';
import { observer } from 'mobx-react-lite';
import AuthenticatedPage from '../auth/authenticatedPage';
import InvoiceModal from '../invoice/invoice-modal';
import { saveVisit } from '../../api/visitTrackerService';

const Subscriptions = observer(() => {
    const rootStore: RootStore = useContext(RootStoreContext);
    const subscriptionsStore = rootStore.subscriptionsStore;
    const { isLoggedIn } = rootStore.userStore;

    const { subscriptions, loading } = subscriptionsStore;



    useEffect(() => {
        document.title = "Desktop call recorder - Subscriptions";
        saveVisit('subscriptions');
    }, []);

    useEffect(() => {
        if (!isLoggedIn) return;
        subscriptionsStore.fetchSubscriptions();
    }, [isLoggedIn]);

    return (
        <AuthenticatedPage>
            <div className="xl:px-20 md:px-12 md:mt-5 px-4">
                <div className="text-center">
                    {loading ?
                        <>
                            <div className="flex flex-row items-center">
                                <div className="mx-auto text-center text-lg font-semibold mt-5 mb-2">Getting subscription...</div>
                            </div>
                        </>
                        :
                        <>
                            {subscriptions.length === 0 ?
                                subscriptionsStore.error !== null ?
                                    <div className="flex flex-row items-center">
                                        <div className="mx-auto text-center text-lg font-semibold mt-5 mb-2">{subscriptionsStore.error}</div>
                                    </div>
                                    :
                                    <div className="flex flex-row items-center">
                                        <div className="mx-auto text-center text-lg font-semibold mt-5 mb-2">You don't have any subscriptions yet.</div>
                                    </div>
                                :
                                <>
                                    <div className="flex flex-row items-center">
                                        <div className="mx-auto text-center text-lg font-semibold mt-5 mb-2">Your subscriptions</div>
                                    </div>
                                    {subscriptions.map(subscription => (
                                        <>
                                            <div key={subscription.id} className={`bg-opacity-100 md:mx-8 border-t border-gray-600
                                                    ${(subscription.isBlocked || subscription.isExpired) ? "bg-red-200"
                                                    : (subscription.renewalLink ? "bg-amber-300" : "bg-white")}`}>
                                                {subscription.renewalLink && (
                                                    <div className=" font-semibold mt-5 mb-2 justify-start">
                                                        {subscription.isExpired ? "Your subscription is expired. " : "Your subscription is about to expire. Renew before expiry date and get discount. "}
                                                    
                                                        <a href={subscription.renewalLink} className="text-blue-500 underline font-bold mx-2">
                                                            Renew now
                                                        </a>
                                                    </div>
                                                )}
                                                <div className={`flex flex-row items-center justify-start px-4  md:mx-8 `}>

                                                    <div className=" text-center text-lg font-semibold mt-5 mb-2">
                                                        Desktop call recorder {subscription.productName}
                                                        Pro subscription
                                                    </div>
                                                    <div className=" text-center text-sm font-normal mt-5 mb-2 mx-4">
                                                        Number of computers: <span className="text-lg font-semibold">{subscription.qnt} </span>
                                                    </div>
                                                    <div className=" text-center text-sm font-normal mt-5 mb-2 mx-4">
                                                        Expiration date: <span className="text-lg font-semibold">{subscription.endDate}</span>
                                                    </div>
                                                    <div className="text-center text-sm font-normal mt-5 mb-2 mx-4">
                                                        Status: <span className={`text-lg font-semibold ${(subscription.isBlocked || subscription.isExpired) ? "text-red-700" : "text-lime-700"}`}>
                                                            {subscription.isBlocked ? "Blocked" : subscription.isExpired ? "Expired" : "Active"}
                                                        </span>
                                                    </div>

                                                </div>

                                            </div>
                                            {
                                                subscription.orders.map(order => (
                                                    <>
                                                        <div key={order.id} className="flex flex-row items-center bg-blue-100 bg-opacity-100 justify-start px-4 md:mx-8">
                                                            <div className="flex flex-col w-1/6">
                                                                <div className="text-center text-lg font-semibold mb-2">Order # {order.id}</div>
                                                                <div className="text-center text-lg font-normal mb-2">Date: {order.orderDate}</div>
                                                            </div>
                                                            <div className="flex ">
                                                                <div className="flex flex-col  text-center mt-1 mb-2 mx-4">
                                                                    <span>Payment method:</span>
                                                                    <span className="text-lg font-semibold">{order.payment?.paymentType}</span>
                                                                </div>
                                                                <div className="flex flex-col  text-center mt-1 mb-2 mx-4">
                                                                    <span>Payment status:</span>
                                                                    <span className="text-lg font-semibold">{order.payment?.paymentStatus}</span>
                                                                </div>
                                                                <div className="flex flex-col  text-center mt-1 mb-2 mx-4">
                                                                    <span>Payment date:</span>
                                                                    <span className="text-lg font-semibold">{order.payment?.dateTime}</span>
                                                                </div>
                                                                <div className="flex flex-col  text-center mt-1 mb-2 mx-4">
                                                                    <span>Amount:</span>
                                                                    <span className="text-lg font-semibold uppercase ">{order.payment?.currency} {order.payment?.amount}</span>
                                                                </div>
                                                                <div className="flex flex-col   text-center mt-1 mb-2 mx-4">
                                                                    <a className="underline" href="" onClick={(e) => {
                                                                        e.preventDefault();
                                                                        if (order.payment) subscriptionsStore.openInvoiceModal(order.payment)
                                                                    }}>
                                                                        Invoice
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div >
                                                    </>
                                                ))
                                            }
                                            <div className="flex flex-row items-start bg-green-100 bg-opacity-100 justify-start pt-3 px-4 md:mx-8">
                                                <div className="w-1/6 ">
                                                    <div className="text-center text-lg font-normal mb-2">Activation codes: </div>
                                                </div>
                                                <div className="flex flex-col items-start">
                                                    {subscription.activationCodes.map((code, index) => (
                                                        <div key={code.code} className="flex flex-row">
                                                            <div key={index} className="text-lg font-semibold mb-2">
                                                                {code.code}
                                                            </div>
                                                            <div className={`text-lg font-semibold mb-2 mx-4 ${code.isActivated ? "" : "hidden"}`}>
                                                                <span className="text-sm font-normal">activated on</span> {code.computerName}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <p style={{ height: '10px' }}></p>
                                        </>
                                    ))}
                                </>
                            }
                        </>
                    }
                </div>
            </div >
            <InvoiceModal />
        </AuthenticatedPage>
    )
});

export default Subscriptions;
