import axios from 'axios';  
import { InvoiceDataDto } from '../models/dtoTypes';
import {API_URL} from '../constants';

export const fetchInvoice = async (paymentId:number):Promise<InvoiceDataDto> => {
        try {
            const response = await axios.get(`${API_URL}/api/order/getinvoicedetails/${paymentId}`, { withCredentials: true });
            return response.data as InvoiceDataDto;
        } catch (error) {
            console.error('Error fetching invoice HTML:', error);
            throw error;
        }
    };

export const postInvoiceDetails = async (data:InvoiceDataDto):Promise<string> => {
        try {
            const response = await axios.post(`${API_URL}/api/order/createinvoice/`, data, { withCredentials: true });
            return response.data as string; 
        } catch (error) {
            console.error('Error posting invoice DTO:', error);
            throw error;
        }
    }
