import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import OrderStore from '../../stores/orderStore';
import AuthenticatedPage from '../auth/authenticatedPage';
import { RootStoreContext } from '../../stores/rootStore';
import { set } from 'mobx';
import loadingGif from '../../assets/images/Blocks-1s-200px.gif';
import { observer } from 'mobx-react-lite';
import { saveVisit } from '../../api/visitTrackerService';
import { PaidOrderDto } from '../../models/dtoTypes';

const PaymentSuccess = () => {
    const rootStore = useContext(RootStoreContext);
    const { isLoggedIn } = rootStore.userStore;
    const { paymentId } = useParams();
    const orderStore = rootStore.orderStore;

    // useEffect(() => {
    //     if (isLoggedIn && paymentId !== undefined) {
    //         orderStore.checkPayment(parseInt(paymentId));
    //     }
    // }, [])
    useEffect(() => {
        if (!isLoggedIn) return;
        saveVisit('payment-success PaymentId: ' + paymentId);
        if (orderStore !== undefined) {
            if (isLoggedIn && paymentId !== undefined) {
                orderStore.checkPayment(parseInt(paymentId));
            }
        }
    }, [isLoggedIn, orderStore, paymentId]);


    return (
        <AuthenticatedPage>
            {orderStore.order === undefined ?
                <p className="mx-auto text-center text-lg font-semibold mt-5"><em>
                    Please wait. We are loading your order details...</em>
                    <div className="flex  items-center  justify-center">
                        <img src={loadingGif} alt="Loading..." />
                    </div>
                </p>
                :
                <div className="flex justify-center">
                    <div className="text-left">

                        {orderStore.order?.error !== null ?
                            <>
                                <p className="mx-auto text-center text-lg font-semibold mt-5">
                                    <em> Sorry there was an error while checking your order</em>
                                </p>
                                <p className="mx-auto text-center text-lg font-semibold mt-5">
                                    <em> {orderStore.order?.error}</em>
                                </p>
                            </>
                            :
                            <>
                                <h3 className="mx-auto text-center text-2xl font-semibold mt-5 mb-2">Thank you for your purchase</h3>

                                <div className="text-left">
                                    {(orderStore.order?.numberOfSeats ?? 0 > 1) ?
                                        <>
                                            <p>Your subscription for the Desktop Call Recorder Pro edition is now available.</p>
                                            <p>Simply use the provided activation codes to activate your Desktop Call Recorder and start enjoying its features.</p>
                                        </>
                                        :
                                        <>
                                            <p>Your subscription for the Desktop Call Recorder Pro edition is now available.</p>
                                            <p>Simply use the provided activation code to activate your Desktop Call Recorder and start enjoying its features.</p>
                                        </>
                                    }
                                    <div className="my-3">
                                        {'activationCodes' in (orderStore.order as PaidOrderDto) ? (orderStore.order as PaidOrderDto).activationCodes.map((code, index) => (
                                            <p key={index}><b>{code}</b></p>
                                        )) : null}
                                    </div>
                                    <p><a className="underline text-indigo-600 hover:text-indigo-800" href="/subscriptions">Subscriptions and order details</a></p>

                                    <p>Download link <a className="underline text-indigo-600 hover:text-indigo-800"
                                        href="https://download.voipcallrecording.com/RecorderSetup.exe">RecorderSetup.exe</a> </p>
                                    <p>For any questions please contact us <a className="underline text-indigo-600 hover:text-indigo-800"
                                        href="mailto:team@desktopcallrecorder.com">team@desktopcallrecorder.com</a></p>
                                </div>
                            </>
                        }
                    </div>
                </div>
            }

        </AuthenticatedPage>
    )
}

export default observer(PaymentSuccess)