import { makeAutoObservable, observable, action, runInAction } from 'mobx';
import { checkPaymentCall, checkRenewPaymentCall } from '../api/orderService';
import { PaidOrderDto, PaidRenewOrderDto } from '../models/dtoTypes';
import { RootStore } from './rootStore';

export default class OrderStore {
    rootStore: RootStore | undefined;
    @observable order: PaidOrderDto  | PaidRenewOrderDto | undefined = undefined;
    @observable error: string | null = null;
    @observable loading = false;



    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    @action
    async checkPayment(paymentId: number) {
        if (this.order && this.order !== null) {
            if (this.order?.paymentId === paymentId) 
                return;
        }
        if (this.loading) return;

        runInAction(() => {
            this.loading = true;
        });

        try {

            const paymentStatus = await checkPaymentCall(paymentId);

            runInAction(() => {
                // Update the paymentStatus in the store
                this.order = paymentStatus;
            });
        } catch (error) {
            // Handle any errors that occur during the payment check
            console.error('Error checking payment:', error);
        }
        runInAction(() => {
            this.loading = false;
        });
    }

    @action
    async checkRenewPayment(paymentId: number) {
        if (this.order && this.order !== null) {
            if (this.order?.paymentId === paymentId) 
                return;
        }
        if (this.loading) return;

        runInAction(() => {
            this.loading = true;
        });

        try {

            const paymentStatus = await checkRenewPaymentCall(paymentId);

            runInAction(() => {
                // Update the paymentStatus in the store
                this.order = paymentStatus;
            });
        } catch (error) {
            // Handle any errors that occur during the payment check
            console.error('Error checking renew payment:', error);
        }
        runInAction(() => {
            this.loading = false;
        });
    }
}
