import axios from 'axios';  
import { pricesRatesDto } from '../models/dtoTypes';
import {API_URL} from '../constants';

export const fetchPrices = async (): Promise<pricesRatesDto> => {
  try {
    const response = await axios.get(API_URL+"/PricesRates");
    return response.data as pricesRatesDto;
  } catch (error) {
    console.error('Failed to fetch prices:', error);
    throw error;
  }
};