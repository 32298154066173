import React, { useEffect } from 'react'
import { saveVisit } from '../../api/visitTrackerService';

type Props = {}

const ServerError = (props: Props) => {
    useEffect(() => {
        saveVisit('server-error');
    }, [])
    return (
        <div className="flex justify-center items-center h-screen">
            <div className="text-center">
                <h1 className="text-xl font-bold">Sorry, there was an error</h1>
            </div>
        </div>
    )
}

export default ServerError