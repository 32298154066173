import React, { useContext, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { RootStore, RootStoreContext } from '../../stores/rootStore'
import spinner from '../../assets/images/Spinner-1s-55px.gif';
import { validateInvoiceDetailsForm } from '../forms/Validation';
import { InvoiceDetailsFormValues } from '../../models/dtoTypes';
import { useReactToPrint } from 'react-to-print';

const InvoiceModal = observer(() => {
    const rootStore: RootStore = useContext(RootStoreContext);
    const invoiceStore = rootStore.invoiceStore;
    const { closeInvoiceModal, isInvoiceModalOpen, noInvoiceHtml,
        invoiceHtml, postInvoiceDetails, loading,
        invoiceBusinessName, invoiceAddress,
        invoiceRefNumber, country,
        isSubmitClicked, isInvoiceDataValid } = invoiceStore;



    const contentRef = useRef<HTMLDivElement>(null);
    const reactToPrintFn = useReactToPrint({ contentRef });


    return (
        <>
            {isInvoiceModalOpen && (
                <>
                    <div className="min-h-screen fixed inset-0 bg-gray-700 bg-opacity-50 overflow-y-auto h-full w-full top-0 right-0 bottom-0 left-0 z-50"
                    >
                        {noInvoiceHtml ? (
                            <div role="alert" className="fixed inset-0 flex items-center justify-center">
                                <div className="relative py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400 container mx-auto w-11/12 md:w-2/3 max-w-lg">
                                    <div className="m-4">
                                        <div >
                                            <h2 className="text-2xl mb-4">Please enter details for invoice</h2>
                                        </div>
                                        <div className="flex items-center mt-6">
                                            <p className="font-bold">Name/Business name</p>
                                            <div className="flex-grow"></div>
                                            <input className={invoiceStore.invoiceBusinessNameFieldStyle}
                                                name="Name" type="text"
                                                value={invoiceBusinessName}
                                                onChange={(e) => invoiceStore.setInvoiceBusinessName(e.target.value)} />
                                        </div>
                                        <div className="flex items-center mt-4">
                                            <p className="font-bold">Billing address</p>
                                            <div className="flex-grow"></div>
                                            <textarea className={invoiceStore.invoiceAddressFieldStyle}
                                                cols={50} name="Address" rows={3}
                                                value={invoiceAddress}
                                                onChange={(e) => invoiceStore.setInvoiceAddress(e.target.value)} />
                                        </div>
                                        {country && (
                                            <div className="flex items-center mt-4">
                                                <p className="font-bold">Country</p>
                                                <div className="flex-grow"></div>
                                                <div>{country}</div>
                                            </div>
                                        )}
                                        <div className="flex items-center mt-4 mb-5">
                                            <p className="font-bold">Your reference number (optional)</p>
                                            <div className="flex-grow"></div>
                                            <input className="w-1/2 bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                                                name="BuyersRefNumber" type="text"
                                                value={invoiceRefNumber}
                                                onChange={(e) => invoiceStore.setInvoiceRefNumber(e.target.value)} />
                                        </div>
                                        <div className="mt-5">
                                            <div className="flex items-center justify-start w-full mt-10">
                                                {loading ? (
                                                    <div className="flex  items-center  justify-center">
                                                        <img src={spinner} alt="Loading..." />
                                                    </div>
                                                ) : (
                                                    <>
                                                        <button
                                                            onClick={() => postInvoiceDetails()}
                                                            type="submit"
                                                            className="rounded hover:bg-blue-400 bg-blue-500 text-white px-8 py-2 text-sm"
                                                            disabled={isInvoiceDataValid === false || isSubmitClicked}>
                                                            Submit details
                                                        </button>
                                                        <button className="focus:outline-none focus:ring-2 focus:ring-offset-2  focus:ring-gray-400 ml-3 bg-gray-100 transition duration-150 text-gray-600 ease-in-out hover:border-gray-400 hover:bg-gray-300 border rounded px-8 py-2 text-sm"
                                                            onClick={closeInvoiceModal}>
                                                            Cancel
                                                        </button>
                                                    </>
                                                )}
                                            </div>
                                        </div>

                                    </div>

                                    <button className="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600"
                                        aria-label="close modal" role="button"
                                        onClick={closeInvoiceModal}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="20" height="20" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" />
                                            <line x1="18" y1="6" x2="6" y2="18" />
                                            <line x1="6" y1="6" x2="18" y2="18" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div className="mx-4 py-1">
                                <div className="bg-white rounded-lg shadow-lg max-w-2xl mx-auto">
                                    <div className="max-full flex flex-row p-2 bg-gray-300">
                                        <div className="basis-1/4">
                                            <button className="rounded hover:bg-blue-400 bg-blue-500 text-white px-8 py-2 text-sm"
                                                onClick={() => reactToPrintFn()}>Print</button>
                                        </div>
                                        <div className="basis-2/4"></div>
                                        <div className="basis-1/4">
                                            <button className="float-right cursor-pointer text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600"
                                                aria-label="close modal" role="button"
                                                onClick={closeInvoiceModal}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="20" height="20" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" fill="none" stroke-linecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" />
                                                    <line x1="18" y1="6" x2="6" y2="18" />
                                                    <line x1="6" y1="6" x2="18" y2="18" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div ref={contentRef} className="m-8 pb-8">
                                        <div dangerouslySetInnerHTML={{ __html: invoiceHtml! }} />
                                    </div>
                                </div>
                            </div>

                        )}
                    </div>
                </>
            )}
        </>
    );
});

export default InvoiceModal;

