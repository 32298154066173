//root store for all stores
import { createContext } from "react";
import { makeAutoObservable, observable, runInAction } from "mobx";
import { PricesList, Rates, pricesRatesDto } from '../models/dtoTypes';

import BundleStore from "./bundleStore";
import { fetchPrices } from "../api/pricesService";
import UserStore from "./userStore";
import PurchaseStore from "./purchaseStore";
import OrderStore from "./orderStore";
import SubscriptionsStore from "./subscriptionsStore";
import InvoiceStore from './invoiceStore'; // Ensure the file exists or update the path
import RenewStore from "./renewStore";

export class RootStore {
  @observable loading: boolean = true;

  @observable priceList: PricesList = {};
  @observable rates: Rates = {};
  @observable ratesLoaded: boolean = false;

  @observable bundle1 = new BundleStore(this, 1);
  @observable bundle2 = new BundleStore(this, 2);
  @observable bundle5 = new BundleStore(this, 5);
  @observable bundleCustom = new BundleStore(this, 10);
  @observable purchaseStore = new PurchaseStore(this, 1);
  @observable orderStore = new OrderStore(this);
  @observable subscriptionsStore = new SubscriptionsStore(this);
  @observable invoiceStore = new InvoiceStore(this);
  @observable renewStore = new RenewStore(this);  


  userStore: UserStore;

  constructor() {
    //get prices and rates from the server
    fetchPrices().then((pricesRates: pricesRatesDto) => {

      //set the prices and rates for each bundle
      runInAction(() => {
        this.priceList = pricesRates.pricesList;
        this.rates = pricesRates.rates;
        this.ratesLoaded = true;
        this.loading = false;
        if (this.priceList !== undefined) {       
          this.bundle1.setPricesAndRates(this.priceList, this.rates);
          this.bundle2.setPricesAndRates(this.priceList, this.rates);
          this.bundle5.setPricesAndRates(this.priceList, this.rates);
          this.bundleCustom.setPricesAndRates(this.priceList, this.rates);
          this.purchaseStore.setPricesAndRates(this.priceList, this.rates);
          this.loading = false;
          console.log("Prices and rates fetched successfully");
        }
      });
    })
      .catch((error) => {
        console.error('Failed to fetch prices:', error);
      });;




    this.userStore = new UserStore(this);

    makeAutoObservable(this);
  }
}

export const RootStoreContext = createContext(new RootStore());