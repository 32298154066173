import React, { Fragment, useContext, useEffect } from 'react';
import { observer } from "mobx-react-lite";
import { RootStoreContext } from '../../stores/rootStore';
import { Link } from 'react-router-dom';
import loadingGif from '../../assets/images/Blocks-1s-200px.gif';
import { saveVisit } from '../../api/visitTrackerService';


const Home: React.FC = observer(() => {
    const rootStore = useContext(RootStoreContext);
    const { loading } = rootStore;
    const bundle1 = rootStore.bundle1;
    const bundle2 = rootStore.bundle2;
    const bundle5 = rootStore.bundle5;
    const bundleCustom = rootStore.bundleCustom;
    const { Qnt, increaseQnt, decreaseQnt } = rootStore.bundleCustom;

    const handelQntInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        rootStore.bundleCustom.setQnt(Number(e.target.value));
    }

    useEffect(() => {
        saveVisit('home');
    }, []);
    
    return (

        <div className="xl:px-20 md:px-12 md:mt-5 px-4">
            <div >
                {loading ?
                    <>
                        <div className="flex justify-center">
                            <img src={loadingGif} />
                        </div>
                        <p className="text-center"><em>Loading...</em></p>
                    </>
                    :
                    <>
                        <div className="text-center pb-4">
                            <h2 className="text-2xl font-semibold">Pro subscription options</h2>
                            <p className="mt-4 md:mb-6"><strong>Desktop call recorder</strong> is licensed per computer. Please choose the
                                required number of licenses. Prices represent annual subscriptions. </p>
                        </div>
                        <div className="grid md:grid-cols-2 xl:grid-cols-4 gap-4 justify-items-center">

                            {/* Card 1 */}
                            <div className="rounded w-72 shadow-xl flex flex-col text-gray-800">
                                <div className="flex flex-row justify-between items-center w-full bg-white bg-opacity-100 rounded-t">
                                    <p className="text-left font-semibold  px-4 py-2">SINGLE COMPUTER</p>
                                    <div className="flex-grow"  ></div>
                                    <div className="flex flex-row items-center justify-center bg-white bg-opacity-90 px-4 ">
                                        <svg xmlns="http://www.w3.org/y2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25" />
                                        </svg>
                                    </div>
                                </div>

                                {/* <!-- Pricing --> */}
                                <div className="flex flex-row items-center pt-8 bg-white bg-opacity-90 pl-12 pr-10 gap-3">
                                    <div className="flex flex-row gap-1">
                                        <span className="text-base">£ </span>
                                        <p className="text-5xl font-semibold">{bundle1.PricePerMonthString}</p>
                                    </div>
                                    <p className="font-light text-xs">Per Computer / Month</p>
                                </div>
                                <div className="flex flex-row items-center justify-center bg-white bg-opacity-90 pt-5 pb-8">
                                    <p className="text-sm text-gray-800 border border-gray-700 border-opacity-90 rounded-full py-1 px-2">
                                        £ {bundle1.BundlePriceString} per year*</p>
                                </div>

                                <div className="flex-grow text-center  font-semibold pl-2 bg-white bg-opacity-90">For use on a single computer
                                </div>

                                <div className="grid grid-cols-12 bg-white bg-opacity-90 px-4 gap-y-3 pt-6 mt-auto">

                                    {/* <!-- Details --> */}
                                    {(bundle1.USDPriceString && bundle1.EURPriceString) &&
                                        <>
                                            <div className="ml-auto mt-4">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" fill="" viewBox="0 0 24 24"
                                                    stroke="currentColor" strokeWidth="1">
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                        d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1z" />
                                                </svg>
                                            </div>
                                            <div className="col-span-11 text-sm flex items-center font-light pl-2 mt-3">£ {bundle1.BundlePriceString} is
                                                aprox.
                                                ${bundle1.USDPriceString} or
                                                €{bundle1.EURPriceString}
                                            </div>
                                        </>
                                    }

                                    {/* <!-- CTA Button --> */}
                                    <div className="col-span-12 mt-auto mb-5 text-gray-100">
                                        <Link to={`/purchase/${bundle1.Qnt}`} className="rounded hover:bg-blue-400 bg-blue-500 w-full py-3">
                                            <button className="rounded hover:bg-blue-400 bg-blue-500 w-full py-3"
                                            >
                                                Purchase
                                            </button>
                                        </Link>

                                    </div>
                                </div>
                            </div>

                            {/* Card 2*/}
                            <div className="rounded w-72 shadow-xl flex flex-col text-gray-800">
                                <div className="flex flex-row justify-between items-center w-full bg-white bg-opacity-100 rounded-t">
                                    <p className="text-left font-semibold  px-4 py-2">TWIN PACK</p>
                                    <div className="flex-grow"  ></div>
                                    <div className="flex flex-row items-center justify-center bg-white bg-opacity-90 px-4 ">
                                        <svg xmlns="http://www.w3.org/y2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25" />
                                        </svg>
                                        <svg xmlns="http://www.w3.org/y2000/svg" fill="none" viewBox="0 0 64 64" strokeWidth="3.5" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M11,13H52.77a2,2,0,0,1,2,2V41.38a3,3,0,0,1-3,3H12.13A3.12,3.12,0,0,1,9,41.28V14.94A2,2,0,0,1,11,13Z" /><path d="M55.25,51h-46a4.52,4.52,0,0,1-4.52-4.51V44.41H59.26V47A4,4,0,0,1,55.25,51Z" />
                                        </svg>
                                    </div>
                                </div>

                                {/* <!-- Pricing --> */}
                                <div className="flex flex-row items-center pt-8 bg-white bg-opacity-90 pl-12 pr-10 gap-3">
                                    <div className="flex flex-row gap-1">
                                        <span className="text-base">£ </span>
                                        <p className="text-5xl font-semibold">{bundle2.PricePerMonthString}</p>
                                    </div>
                                    <p className="font-light text-xs">Per Computer / Month</p>
                                </div>
                                <div className="flex flex-row items-center justify-center bg-white bg-opacity-90 pt-5 pb-8">
                                    <p className="text-sm text-gray-800 border border-gray-700 border-opacity-90 rounded-full py-1 px-2">
                                        £ {bundle2.BundlePriceString} per year*</p>
                                </div>

                                <div className="flex-grow text-center  font-semibold pl-2 bg-white bg-opacity-90">2 computers
                                </div>

                                <div className="grid grid-cols-12 bg-white bg-opacity-90 px-4 gap-y-3 pt-6 mt-auto">

                                    {/* <!-- Details --> */}
                                    {(bundle2.USDPriceString && bundle2.EURPriceString) &&
                                        <>
                                            <div className="ml-auto mt-4">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" fill="" viewBox="0 0 24 24"
                                                    stroke="currentColor" strokeWidth="1">
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                        d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1z" />
                                                </svg>
                                            </div>
                                            <div className="col-span-11 text-sm flex items-center font-light pl-2 mt-3">£ {bundle2.BundlePriceString} is
                                                aprox.
                                                ${bundle2.USDPriceString} or
                                                €{bundle2.EURPriceString}
                                            </div>
                                        </>
                                    }

                                    {/* <!-- CTA Button --> */}
                                    <div className="col-span-12 mt-auto mb-5 text-gray-100">
                                        <Link to={`/purchase/${bundle2.Qnt}`} className="rounded hover:bg-blue-400 bg-blue-500 w-full py-3">
                                            <button className="rounded hover:bg-blue-400 bg-blue-500 w-full py-3"
                                            >
                                                Purchase
                                            </button>
                                        </Link>

                                    </div>
                                </div>
                            </div>

                            {/* Card 5 */}
                            <div className="rounded w-72 shadow-xl flex flex-col text-gray-800">
                                <div className="flex flex-row justify-between items-center w-full bg-white bg-opacity-100 rounded-t">
                                    <p className="text-left font-semibold  px-4 py-2">SMALL OFFICE</p>
                                    <div className="flex-grow"  ></div>
                                    <div className="flex flex-row items-center justify-center bg-white bg-opacity-90 px-4 ">
                                        <svg xmlns="http://www.w3.org/y2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25" />
                                        </svg>
                                        <p className="text-md font-semibold text-gray-800 pl-1"> X {bundle5.Qnt}</p>
                                    </div>
                                </div>

                                {/* <!-- Pricing --> */}
                                <div className="flex flex-row items-center pt-8 bg-white bg-opacity-90 pl-12 pr-10 gap-3">
                                    <div className="flex flex-row gap-1">
                                        <span className="text-base">£ </span>
                                        <p className="text-5xl font-semibold">{bundle5.PricePerMonthString}</p>
                                    </div>
                                    <p className="font-light text-xs">Per Computer / Month</p>
                                </div>
                                <div className="flex flex-row items-center justify-center bg-white bg-opacity-90 pt-5 pb-8">
                                    <p className="text-sm text-gray-800 border border-gray-700 border-opacity-90 rounded-full py-1 px-2">
                                        £ {bundle5.BundlePriceString} per year*</p>
                                </div>

                                <div className="flex-grow text-center  font-semibold pl-2 bg-white bg-opacity-90">Up to 5 computers
                                </div>

                                <div className="grid grid-cols-12 bg-white bg-opacity-90 px-4 gap-y-3 pt-6 mt-auto">

                                    {/* <!-- Details --> */}
                                    {(bundle5.USDPriceString && bundle5.EURPriceString) &&
                                        <>
                                            <div className="ml-auto mt-4">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" fill="" viewBox="0 0 24 24"
                                                    stroke="currentColor" strokeWidth="1">
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                        d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1z" />
                                                </svg>
                                            </div>
                                            <div className="col-span-11 text-sm flex items-center font-light pl-2 mt-3">£ {bundle5.BundlePriceString} is
                                                aprox.
                                                ${bundle5.USDPriceString} or
                                                €{bundle5.EURPriceString}
                                            </div>
                                        </>
                                    }

                                    {/* <!-- CTA Button --> */}
                                    <div className="col-span-12 mt-auto mb-5 text-gray-100">
                                        <Link to={`/purchase/${bundle5.Qnt}`} className="rounded hover:bg-blue-400 bg-blue-500 w-full py-3">
                                            <button className="rounded hover:bg-blue-400 bg-blue-500 w-full py-3"
                                            >
                                                Purchase
                                            </button>
                                        </Link>

                                    </div>
                                </div>
                            </div>

                            {/* Card custom */}
                            <div className="rounded w-72 shadow-xl flex flex-col text-gray-800">
                                <div className="flex flex-row justify-between items-center w-full bg-white bg-opacity-100 rounded-t">
                                    <p className="text-left font-semibold  px-4 py-2">CUSTOM</p>
                                    <div className="flex-grow"  ></div>
                                    <div className="flex flex-row items-center justify-center bg-white bg-opacity-90 px-4 ">
                                        <svg xmlns="http://www.w3.org/y2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25" />
                                        </svg>
                                        <p className="text-md font-semibold text-gray-800 pl-1"> X {bundleCustom.Qnt}</p>
                                    </div>
                                </div>

                                {/* <!-- Pricing --> */}
                                <div className="flex flex-row items-center pt-8 bg-white bg-opacity-90 pl-12 pr-10 gap-3">
                                    <div className="flex flex-row gap-1">
                                        <span className="text-base">£ </span>
                                        <p className="text-5xl font-semibold">{bundleCustom.PricePerMonthString}</p>
                                    </div>
                                    <p className="font-light text-xs">Per Computer / Month</p>
                                </div>
                                <div className="flex flex-row items-center justify-center bg-white bg-opacity-90 pt-5 pb-8">
                                    <p className="text-sm text-gray-800 border border-gray-700 border-opacity-90 rounded-full py-1 px-2">
                                        £ {bundleCustom.BundlePriceString} per year*</p>
                                </div>

                                <div className="text-center text-sm font-semibold flex-col items-center bg-white bg-opacity-90">
                                    <div>Choose the number of licenses you need</div>
                                    <div className="flex justify-center">
                                        <div className="custom-number-input h-10 w-32 ">
                                            <div className="flex flex-row item h-10 w-full rounded-lg relative bg-transparent mt-1">
                                                <button data-action="decrement" onClick={decreaseQnt}
                                                    className=" bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-l cursor-pointer outline-none">
                                                    <span className="m-auto text-2xl font-thin">−</span>
                                                </button>
                                                <input type="number"
                                                    className="outline-none focus:outline-none text-center w-full bg-gray-300 font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700  outline-none"
                                                    value={Qnt}
                                                    onChange={handelQntInputChange}
                                                    name="custom-input-number" />
                                                <button data-action="increment" onClick={increaseQnt}
                                                    className="bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-r cursor-pointer">
                                                    <span className="m-auto text-2xl font-thin">+</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>


                                </div>


                                <div className="grid grid-cols-12 bg-white bg-opacity-90 px-4 gap-y-3 pt-6 mt-auto">

                                    {/* <!-- Details --> */}
                                    {(bundleCustom.USDPriceString && bundleCustom.EURPriceString) &&
                                        <>
                                            <div className="ml-auto mt-4">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" fill="" viewBox="0 0 24 24"
                                                    stroke="currentColor" strokeWidth="1">
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                        d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1z" />
                                                </svg>
                                            </div>
                                            <div className="col-span-11 text-sm flex items-center font-light pl-2 mt-3">£ {bundleCustom.BundlePriceString} is
                                                aprox.
                                                ${bundleCustom.USDPriceString} or
                                                €{bundleCustom.EURPriceString}
                                            </div>
                                        </>
                                    }

                                    {/* <!-- CTA Button --> */}
                                    <div className="col-span-12 mt-auto mb-5 text-gray-100">
                                        <Link to={`/purchase/${Qnt}`} className="rounded hover:bg-blue-400 bg-blue-500 w-full py-3">
                                            <button className="rounded hover:bg-blue-400 bg-blue-500 w-full py-3"
                                            >
                                                Purchase
                                            </button>
                                        </Link>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </>

                }
            </div>
        </div>

    );
}
);
export default Home;
