import axios from 'axios';  
import { PaymentDtoV2, OrderDtoV2, ActivationCodeDto, SubscriptionDtoV2, RenewSubscriptionDto } from '../models/dtoTypes';
import {API_URL} from '../constants';

export const fetchSubscriptions = async (): Promise<SubscriptionDtoV2[]> => {
  try {
    const response = await axios.get(API_URL+'/api/order/getsubscriptions/', { withCredentials: true });
    return response.data as SubscriptionDtoV2[];
  } catch (error) {
    console.error('Failed to fetch subscriptions:', error);
    throw error;
  }
};

export const fetchRenewal = async (renewalCode: string): Promise<RenewSubscriptionDto> => {
  try {
    const response = await axios.get(API_URL+'/api/subscription/renew/'+encodeURIComponent(renewalCode), { withCredentials: true });
    return response.data as RenewSubscriptionDto;
  } catch (error) {
    console.error('Failed to fetch renewal:', error);
    throw error;
  }
}

export const fetchRenewalByActivationCodeEncoded = async (activationCodeEncoded: string): Promise<RenewSubscriptionDto> => {
  try {
    const response = await axios.get(API_URL+'/api/subscription/renew/a/'+encodeURIComponent(activationCodeEncoded), { withCredentials: true });
    return response.data as RenewSubscriptionDto;
  } catch (error) {
    console.error('Failed to fetch renewal:', error);
    throw error;
  }
}

