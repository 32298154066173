import { Fragment, useContext } from 'react';
import Recorder32 from '../../assets/images/Recorder32.png';
import { RootStoreContext } from '../../stores/rootStore';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const Header: React.FC = () => {

  const rootStore = useContext(RootStoreContext);
  const { isLoggedIn, OpenLoginForm, Logout } = rootStore.userStore;

  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <nav className="flex font-custom font-medium bg-navcustoml sticky top-0 h-16 z-10 items-center p-6">


      <div className="flex hidden md:block w-32">
      </div>
      <div className="flex-none w-16 ">
        <a href="/">
          <img src={Recorder32} alt="alternative" className="h-8 w-auto" />
        </a>
      </div>

      <div className="flex-grow ml-1">
        <a href="https://desktopcallrecorder.com/" className="text-white  text-2xl ml-2">
          Desktop call recorder
        </a>
      </div>
      <div className="">
        <ul className="flex space-x-4">
          <li className="flex items-center">
            <a className="text-white hover:text-gray-300 ml-2 text-xs md:text-sm lg:text-base"
              href="https://download.voipcallrecording.com/RecorderSetup.exe">
              DOWNLOAD
            </a>
          </li>
          <li>
                  <p className="text-white">|</p>
                </li>
          {!isLoggedIn ? (
            <>
              <li className="flex items-center">
                <a className="text-white hover:text-gray-300 text-xs md:text-sm lg:text-base" href="" onClick={(e) => {
                  e.preventDefault();
                  OpenLoginForm();
                }}>Sign in</a>
              </li>
            </>

          )
            :
            (
              <>
                {currentPath === '/subscriptions' ? (
                  <Link to="/" className="text-white hover:text-gray-300 text-xs md:text-sm lg:text-base">
                    GET SUBSCRIPTION
                  </Link>
                ) : (
                  <li>

                    <Link to="/subscriptions" className="text-white hover:text-gray-300 text-xs md:text-sm lg:text-base">
                      MY SUBSCRIPTIONS
                    </Link>
                  </li>
                )}
                <li>
                  <p className="text-white">|</p>
                </li>
                <li className="flex items-center">
                  <a className="text-white hover:text-gray-300 text-xs md:text-sm lg:text-base" href="" onClick={(e) => {
                    e.preventDefault();
                    Logout();
                  }}>Sign out</a>
                </li>
              </>

            )
          }
        </ul>
      </div>

      <div className="">
        <ul className="flex space-x-4">

        </ul>
      </div>


    </nav>
  );
}


export default observer(Header);