import { action, observable, computed, makeAutoObservable, runInAction } from "mobx";
import { RootStore } from "./rootStore";
import BundleStore from "./bundleStore";
import { CountriesDto, CountryDto, PricesList, Rates, PurchaseDto } from "../models/dtoTypes";
import { checkDiscountCode, fetchCountries, purchasePost } from "../api/orderService";

export default class PurchaseStore {
    rootStore: RootStore | undefined;
    @observable bundle: BundleStore;
    @observable countries: CountryDto[] = [];

    constructor(rootStore: RootStore, qnt: number) {
        this.rootStore = rootStore;
        this.bundle = new BundleStore(rootStore, qnt);
        this.tryRestorePurchaseState();

        fetchCountries().then((response) => {
            runInAction(() => {
                if (response.detectedCountryCode !== undefined && response.detectedCountryCode !== null) {
                    this.invoiceCountryIsoCode = response.detectedCountryCode;
                } else {
                    this.invoiceCountryIsoCode = 'GB';
                }
                this.countries = response.countries;
            });
        }).catch((error) => {
            console.error('Failed to fetch countries:', error);
        });
        makeAutoObservable(this);
    }

    @action setPricesAndRates(priceList: PricesList, rates: Rates) {
        this.bundle.setPricesAndRates(priceList, rates);
    }

    //#region Discount code properties
    @observable iHaveDiscount: boolean = false;
    @action setIHaveDiscount(value: boolean) {
        this.iHaveDiscount = value;
    }
    @observable discountCode: string = '';
    @observable showCheckingDiscountSpinner: boolean = false;
    @observable discountPercentage: number = 0;
    @computed get discountPercentageString() {
        return this.discountPercentage.toFixed(0);
    }

    @action setDiscountCode(value: string) {
        this.discountError = '';
        this.discountPercentage = 0;

        //trimming and uppercasing the discount code, then checking its length
        let dCode: string = value.trim().toUpperCase();
        this.discountCode = dCode;
        if (dCode.length !== 6) {
            this.showCheckingDiscountSpinner = false;
            return;
        }
        this.discountCode = dCode;
        this.showCheckingDiscountSpinner = true;

        checkDiscountCode(dCode).then((response) => {
            runInAction(() => {
                this.showCheckingDiscountSpinner = false;
                this.isDiscountValid = response.isDiscountCodeValid;
                if (response.isDiscountCodeValid) {
                    this.discountPercentage = response.discountPercentage;
                }
                else {
                    this.discountPercentage = 0;
                }

                this.discountError = response.discountCodeError;
            });
        }).catch((error) => {
            this.showCheckingDiscountSpinner = false;
            this.discountError = error.message;
        });

    }

    @observable discountError: string = '';

    @observable isDiscountValid: boolean = false;

    @computed get codeDiscount() {
        return this.bundle.BundlePrice * this.discountPercentage / 100;
    }
    @computed get codeDiscountString() {
        return this.codeDiscount.toFixed(2);
    }

    @computed get totalDiscount() {
        return this.codeDiscount + this.bundle.Saving;
    }
    
    @computed get totalDiscountString() {
        return this.totalDiscount.toFixed(2);
    }
    @computed get totalAfterDiscount() {
        return this.bundle.BundlePrice - this.codeDiscount;
    }
    @computed get totalAfterDiscountString() {
        return this.totalAfterDiscount.toFixed(2);
    }

    //#endregion

    //#region Prices and rates

    @computed get USDPrice(): number { return this.totalAfterDiscount * this.bundle.rates["GBPUSD"]; }
    @computed get EURPrice(): number { return this.totalAfterDiscount * this.bundle.rates["GBPEUR"]; }

    //computer property for the price in USD and EUR based on the current exchange rates as string with 2 decimal places
    @computed get USDPriceString(): string { return this.USDPrice.toFixed(0); }
    @computed get EURPriceString(): string { return this.EURPrice.toFixed(0); }

    //#endregion

    //#region invoice properties
    @observable isInvoiceNeeded: boolean = false;
    @action setInvoiceNeeded(value: boolean) {
        this.isInvoiceNeeded = value;
    }

    @observable invoiceRefNumber: string = '';
    @action setInvoiceRefNumber(value: string) {
        this.invoiceRefNumber = value;
    }

    fieldStyleNormal: string = "w-1/2 bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5";
    fieldStyleError: string = " w-1/2 bg-red-100 border border-red-600 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5";

    @observable invoiceBusinessNameTouched: boolean = false;
    @observable invoiceBusinessName: string = '';
    @action setInvoiceBusinessName(value: string) {
        this.invoiceBusinessName = value;
        this.invoiceBusinessNameTouched = true;
    }
    @computed get invoiceBusinessNameFieldStyle() {
        if (this.invoiceBusinessNameTouched && this.invoiceBusinessName.trim() === '') {
            return this.fieldStyleError;
        }
        return this.fieldStyleNormal;
    }

    @observable invoiceAddress: string = '';
    @action setInvoiceAddress(value: string) {
        this.invoiceAddress = value;
        this.invoiceAddressTouched = true;
    }
    @observable invoiceAddressTouched: boolean = false;
    @computed get invoiceAddressFieldStyle() {
        if (this.invoiceAddressTouched && this.invoiceAddress.trim() === '') {
            return this.fieldStyleError;
        }
        return this.fieldStyleNormal;
    }

    @observable invoiceCountryIsoCodeTouched: boolean = false;
    @observable invoiceCountryIsoCode: string = '';
    @action setInvoiceCountryIsoCode(value: string) {
        this.invoiceCountryIsoCode = value;
        this.invoiceCountryIsoCodeTouched = true;
    }
    @computed get invoiceCountryIsoCodeFieldStyle() {
        if (this.invoiceCountryIsoCodeTouched && this.invoiceCountryIsoCode.trim() === '') {
            return this.fieldStyleError;
        }
        return this.fieldStyleNormal;
    }

    @computed get isInvoiceDataValid() {
        if (this.isInvoiceNeeded) {
            if (this.invoiceBusinessName === '') {
                return false;
            }
            if (this.invoiceAddress === '') {
                return false;
            }
            if (this.invoiceCountryIsoCode === '') {
                return false;
            }
        }
        return true;
    }
    //#endregion

    // proccesing order properties
    @observable isCheckoutClicked: boolean = false;

    @action HandleCheckoutClicked() {
        this.isCheckoutClicked = true;
        this.invoiceBusinessNameTouched = true;
        this.invoiceAddressTouched = true;
        this.invoiceCountryIsoCodeTouched = true;
        if (!this.isInvoiceDataValid) {
            this.isCheckoutClicked = false;
            return;
        }

        try {
            this.PurchaseError = '';
            var purchaseDto: PurchaseDto = {
                Url: window.location.origin,
                Qnt: this.bundle.Qnt,
                DiscountCode: this.iHaveDiscount ? this.discountCode : '',
                Name: this.invoiceBusinessName,
                Address: this.invoiceAddress,
                BuyersRefNumber: this.invoiceRefNumber,
                CountryIsoCode: this.invoiceCountryIsoCode,
                InvoiceNeeded: this.isInvoiceNeeded,
                BuyersVatNumber: ''
            };
            this.savePurchaseState(purchaseDto);

            purchasePost(purchaseDto).then((responseUrl) => {
                runInAction(() => {
                    window.location.href = responseUrl;
                });
            }).catch((error) => {
                runInAction(() => {
                    console.error('Failed to purchase:', error);
                    this.PurchaseError = "Something went wrong: " + error.message;
                    this.isCheckoutClicked = false;
                });
            });
        } catch (error) {
            runInAction(() => {
                console.error('Failed to create purchaseDto:', error);
                this.PurchaseError = "Something went wrong: ";
                this.isCheckoutClicked = false;
            });
            return;
        }

    }
    savePurchaseState(purchaseDto: PurchaseDto) {
        localStorage.setItem('purchaseDto', JSON.stringify(purchaseDto));
    }

    tryRestorePurchaseState() {
        var purchaseDto = localStorage.getItem('purchaseDto');
        if (purchaseDto !== null) {
            var dto = JSON.parse(purchaseDto);
            this.invoiceBusinessName = dto.Name;
            this.invoiceAddress = dto.Address;
            this.invoiceCountryIsoCode = dto.CountryIsoCode;
            this.invoiceRefNumber = dto.BuyersRefNumber;
            this.isInvoiceNeeded = dto.InvoiceNeeded;
            this.iHaveDiscount = dto.DiscountCode !== '';
            this.discountCode = dto.DiscountCode;
            this.bundle.setQnt(dto.Qnt);
        }
    }

    @observable PurchaseError: string = '';

    @computed get isOrderValid() {
        if (!this.isInvoiceDataValid) {
            return false;
        }
        return this.bundle.Qnt > 0;
    }


}