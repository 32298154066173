import { InvoiceDetailsFormValues } from '../../models/dtoTypes';

interface LoginFormValues {
  email: string;
  password: string;
}

interface RegisterFormValues {
  email: string;
  password: string;
}

interface ForgotFormValues {
  email: string;
}

interface ResetPasswordFormValues {
  password: string;
}

export const validateInvoiceDetailsForm = (values: InvoiceDetailsFormValues) => {
  const errors: Partial<InvoiceDetailsFormValues> = {};
  if (values.name === undefined || values.name === '') {errors.name = 'Name is required'; return errors;}
  if (values.address === undefined || values.address === '') {errors.address = 'Address is required'; return errors;}
  if (values.name.length < 3) errors.name = 'Name must be at least 3 characters long';
  if (values.address.length < 10) errors.address = 'Address is too short';
  return errors;
}

export const validateLoginRegisterForm = (values: LoginFormValues) => {
  const errors: Partial<LoginFormValues> = {};
  const emailError = validateEmail(values.email);
  if (emailError) errors.email = emailError;

  const passwordError = validatePassword(values.password);
  if (passwordError) errors.password = passwordError;

  return errors;
};

export const validateRegisterForm = (values: RegisterFormValues) => {
  const errors: Partial<RegisterFormValues> = {};
  const emailError = validateEmail(values.email);
  if (emailError) errors.email = emailError;

  const passwordError = validatePassword(values.password);
  if (passwordError) errors.password = passwordError;

  return errors;
}

export const validateForgotForm = (values: ForgotFormValues) => {
  const errors: Partial<ForgotFormValues> = {};
  const emailError = validateEmail(values.email);
  if (emailError) errors.email = emailError;

  return errors;
}

export const validateResetPasswordForm = (values: ResetPasswordFormValues) => {
  const errors: Partial<RegisterFormValues> = {};
 
  const passwordError = validatePassword(values.password);
  if (passwordError) errors.password = passwordError;

  return errors;
}

export const validateEmail = (email: string) => {
  // Simple regex for basic email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!email) return 'Email is required';
  if (!emailRegex.test(email)) return 'Invalid email address';
  return undefined;
};

export const validatePassword = (password: string) => {
  if (!password) return 'Password is required';
  if (password.length < 6) return 'Password must be at least 6 characters long';
  return undefined;
};