import React, { FC, ReactNode, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStore, RootStoreContext } from '../../stores/rootStore';

interface AuthenticatedPageProps {
    children: ReactNode;
}

const AuthenticatedPage: FC<AuthenticatedPageProps> = ({ children }) => {
    const rootStore = useContext(RootStoreContext);
    const userStore = rootStore.userStore;

    if (!userStore.isLoggedIn) {
        userStore.OpenLoginForm();
    }

    return userStore.isLoggedIn ? 
        <>{children}</> 
        :
        <>
            {userStore.isLoggedIn ?
                <>{children}</>
                :
                <div className='flex justify-center'>
                    <h5>Please login to see this page</h5>
                </div>
            }
        </>


};

export default observer(AuthenticatedPage);