import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Form, Field, FieldRenderProps } from 'react-final-form';
import { IResetPasswordInputs } from '../../models/dtoAuthTypes';
import { resetPasswordCall } from '../../api/authService';
import { validateResetPasswordForm } from '../forms/Validation';
import TextInput from '../forms/TextInput';
import { saveVisit } from '../../api/visitTrackerService';

const ResetPassword = () => {
    const { ResetCode } = useParams();
    const ResetCodeValid: boolean = ResetCode !== undefined && ResetCode.length === 16;
    const [ErrorConnection, SetError] = useState<string>('');
    const [SuccessResult, SetSuccess] = useState<boolean>(false);

    const handleResetFormSubmit = async (values: IResetPasswordInputs) => {
        try {
            const err = await resetPasswordCall(values);
            SetError(err);
            if (err === '') {
                SetSuccess(true);
            }
        } catch (error) {
            // Handle error if necessary
        }
    };
    useEffect(() => {
        saveVisit('reset-password ResetCode: ' + ResetCode);
    }, [ResetCode]);

    return (
        <>
            {SuccessResult && (
                <div className=" m-12 ">
                    <div className="text-center w-full">
                        <h1 className="text-2xl text-bold">Password Reset</h1>
                        <p>Your password has been reset successfully.</p>
                    </div>
                </div>
            )}

            {(ResetCodeValid && !SuccessResult) && (
                <div className=" m-12 ">
                    <div className="text-center w-full">

                        <Form
                            initialValues={{ code: ResetCode }}
                            onSubmit={handleResetFormSubmit}
                            validate={validateResetPasswordForm}
                            render={({ handleSubmit, submitting, invalid, pristine }) => (
                                <form onSubmit={handleSubmit}>
                                    <div role="alert" className="fixed @HiddenVar inset-0 flex items-center justify-center">
                                        <div className="relative py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400 container mx-auto w-11/12 md:w-2/3 max-w-lg">
                                            <div className="mb-5">
                                                <Field
                                                    name="code"
                                                    type="hidden"
                                                    component="input"
                                                />
                                                {ErrorConnection !== '' &&
                                                    <label className="text-red-500 italic mb-2 block">{ErrorConnection}</label>
                                                }
                                                <label className="text-gray-800 text-sm font-bold leading-tight tracking-normal">New Password</label>
                                                <Field
                                                    name="password"
                                                    component={TextInput as React.ComponentType<FieldRenderProps<string, HTMLElement, string>>}
                                                    type="password"
                                                    placeholder="New Password"
                                                />
                                            </div>
                                            <button type="submit" disabled={submitting || invalid || pristine} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Set new password</button>
                                        </div>
                                    </div>
                                </form>
                            )} />
                    </div>
                </div>
            ) }

            { !ResetCodeValid && (
                <div className=" m-12 ">
                    <div className="text-center w-full">
                        <h1 className="text-2xl text-bold">Invalid Reset Link</h1>
                    </div>
                </div>
            )}


        </>
    )
}

export default ResetPassword