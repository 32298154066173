import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RootStoreContext } from '../../stores/rootStore';
import loadingGif from '../../assets/images/Blocks-1s-200px.gif';
import loadingGifSmall from '../../assets/images/Spinner-1s-55px.gif';
import { observer } from "mobx-react-lite";
import { saveVisit } from '../../api/visitTrackerService';

const RenewSubscription = observer(() => {
    const { subscriptionCode } = useParams();
    const { activationCodeEncoded } = useParams();

    const rootStore = useContext(RootStoreContext);
    const renewStore = rootStore.renewStore;

    const { loading } = renewStore;
    useEffect(() => {
        if (subscriptionCode) {
            renewStore.setSubscription(subscriptionCode);
            saveVisit('RenewSubscription: ' + renewStore.subscriptionId);
        }
        else if (activationCodeEncoded) {
            renewStore.setSubscriptionByActivationCode(activationCodeEncoded);
            saveVisit('RenewSubscription visisted by activationCodeEncoded: ' + renewStore.subscriptionId);
        }
    }, [subscriptionCode, activationCodeEncoded]);

    const handlePromoInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log(e.target.value);
        renewStore.setDiscountCode(e.target.value);
    }


    return (

        <div className="xl:px-20 md:px-12 md:mt-5 px-4">
            <div className="text-center">
                {loading ?
                    <>
                        <div className="flex justify-center">
                            <img src={loadingGif} />
                        </div>
                        <p className="text-center"><em>Loading...</em></p>
                    </>
                    :
                    <>

                        <div className="xl:px-20 md:px-12 md:mt-5">
                            <div className="text-center pb-4">
                                <h2 className="text-2xl font-semibold">Renew Your Subscription</h2>
                                <p className="mt-4 md:mb-6">Your <strong>Desktop call recorder Pro</strong> subscription &nbsp;
                                    {renewStore.isNotExpiredYet ?
                                        <>
                                            will expire on <strong>{renewStore.endDate}</strong>
                                        </>
                                        :
                                        <>
                                            has expired on <strong> {renewStore.endDate}</strong>
                                        </>
                                    }
                                </p>
                            </div>

                        </div>

                        <div className="lg:w-6/12 mx-auto">

                            <div className="rounded shadow-lg  ">
                                {/* order form body */}
                                <div className="flex w-full bg-white bg-opacity-100 justify-start px-4 pt-4 pb-4">
                                    <span className="font-semibold text-lg text-left mb-1">DESKTOP CALL RECORDER</span>
                                </div>

                                <div className="flex flex-col bg-white bg-opacity-80 justify-start px-4">
                                    <div className="flex items-center">
                                        <p>PRO subscription renewal</p>
                                        <div className="flex-grow"></div>
                                        <p className="text-right">x 12 months</p>
                                    </div>
                                    {/* <!--qnantity--> */}
                                    <div className="flex mt-4 mb-2 items-center">
                                        <h4 className="">Number of computers</h4>
                                        <div className="flex-grow"></div>
                                        < p className="text-right">{renewStore.qnt}</p>

                                    </div>

                                    {/* <!--price per computer--> */}
                                    <div className="flex items-center ">
                                        <p>Price per computer per year</p>
                                        <div className="flex-grow text-left pr-3 text-sm pl-3">(£ {renewStore.PricePerMonthString} x 12 months)
                                        </div>
                                        <p className=" border-b border-gray-600 ">£ {renewStore.pricePerUserString}</p>
                                    </div>



                                    {/* <!--early renewal discount--> */}
                                    {renewStore.earlyRenewalDiscountApplied &&
                                        <div className="flex items-center mt-2 mb-3">
                                            <p>Early renewal discount <span className='font-semibold'>{renewStore.renewalDiscountString}%</span></p>
                                            <div className="flex-grow"></div>
                                            <p className="text-red-500">£ {renewStore.renewalDiscountAmountString}</p>
                                        </div>
                                    }


                                    {/* <!--Promo Code--> */}
                                    {renewStore.iHaveDiscount ?
                                        <>
                                            {renewStore.isDiscountCodeValid ?
                                                <>
                                                    <div className="flex items-center mt-2">
                                                        <p>Discount code applied </p>
                                                        <div className="flex-grow"></div>
                                                        <p className="border-gray-500">
                                                            <span className="text-red-500">£ {renewStore.discountCodeAmountString} </span>
                                                            ( {renewStore.discountPercentageString} % )
                                                        </p>
                                                    </div>
                                                    {/* <!--total discount--> */}
                                                    <div className="flex items-center mt-2">
                                                        <p>Total Discount Savings</p>
                                                        <div className="flex-grow"></div>
                                                        <p className="text-red-500">£ {renewStore.discountCodeAmountString}</p>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className="flex items-center mt-4">
                                                        <p className="font-semibold">Promo Code</p>
                                                        <div className="flex-grow"></div>
                                                        {renewStore.showCheckingDiscountSpinner ?

                                                            <div >
                                                                <div className="flex-grow text-right mr-2"> Checking....</div>
                                                                <img src={loadingGifSmall} style={{ width: '24px', height: '24px' }} />
                                                            </div>
                                                            :
                                                            <input
                                                                className="w-24 h-7 bg-white border border-gray-400 text-gray-900 font-semibold text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                                                                value={renewStore.discountCode}
                                                                onChange={(e) => handlePromoInputChange(e)}
                                                                type="text" />
                                                        }
                                                    </div>
                                                    {/* <!--error message--> */}
                                                    {renewStore.discountError !== '' &&
                                                        <div className="flex text-right">
                                                            <div className="flex-grow"></div>
                                                            <p className="text-red-500 ">{renewStore.discountError}</p>
                                                        </div>
                                                    }
                                                </>
                                            }
                                        </>
                                        :
                                        <div className="flex items-center mt-4">
                                            <div className="flex-grow"></div>
                                            <p className="font-semibold mr-6">I have discount code</p>


                                            <div >
                                                <input
                                                    className="justify-center mt-1"
                                                    name="DiscountCodeTick"
                                                    checked={renewStore.iHaveDiscount}
                                                    onChange={(e) => renewStore.setIHaveDiscount(e.target.checked)}
                                                    type="checkbox" />
                                            </div>
                                        </div>
                                    }
                                    {/* <!-- end of discount code--> */}

                                    {/* <!--total after discount--> */}
                                    <div className="flex items-center mt-2">
                                        <p className="font-bold">Total</p>
                                        <div className="flex-grow"></div>
                                        <p className="font-bold border-b border-gray-600 ">£ {renewStore.totalAfterDiscountString}</p>
                                    </div>
                                    <div className=" border-b border-gray-600 mt-3"></div>
                                    {/* <!--invoice checkbox--> */}
                                    <div className="flex items-center mt-4">
                                        <input type="checkbox" title="I need invoice"
                                            checked={renewStore.isInvoiceNeeded}
                                            onChange={(e) => renewStore.setInvoiceNeeded(e.target.checked)} />
                                        <p className="ml-2">Add your details for invoice (optional)</p>
                                        <div className="flex-grow"></div>
                                        <p className="text-xs text-gray-600">* The amount is shown in £ (Great Britain Pounds GBP)</p>
                                    </div>
                                    {/* <!--usd eur rates--> */}
                                    <div className="flex items-center mb-4">
                                        <div className="flex-grow"></div>
                                        {(renewStore.rootStore?.ratesLoaded) &&

                                            <p className="text-xs text-gray-600">£{renewStore.totalAfterDiscountString} is aprox.
                                                USD {renewStore.USDPriceString} or
                                                EUR {renewStore.EURPriceString}</p>
                                        }
                                    </div>

                                    {/* <!--invoice details--> */}
                                    {renewStore.isInvoiceNeeded &&
                                        <>
                                            <div className=" border-b border-gray-600 mb-3"></div>
                                            <div className="flex items-center mt-4">
                                                <p className="font-bold">Name/Business name</p>
                                                <div className="flex-grow"></div>
                                                <input className={renewStore.invoiceBusinessNameFieldStyle}
                                                    name="Name" type="text"
                                                    value={renewStore.invoiceBusinessName}
                                                    onChange={(e) => renewStore.setInvoiceBusinessName(e.target.value)} />
                                            </div>
                                            <div className="flex items-center mt-4">
                                                <p className="font-bold">Billing address</p>
                                                <div className="flex-grow"></div>
                                                <textarea className={renewStore.invoiceAddressFieldStyle}
                                                    cols={50} name="Address" rows={3}
                                                    value={renewStore.invoiceAddress}
                                                    onChange={(e) => renewStore.setInvoiceAddress(e.target.value)} />
                                            </div>
                                            <div className="flex items-center mt-4">
                                                <p className="font-bold">Country</p>
                                                <div className="flex-grow"></div>
                                                <select className={renewStore.invoiceCountryIsoCodeFieldStyle}
                                                    value={renewStore.invoiceCountryIsoCode}
                                                    onChange={(e) => renewStore.setInvoiceCountryIsoCode(e.target.value)}>
                                                    {renewStore.countries &&
                                                        renewStore.countries.map((country) => (
                                                            <option key={country.code} value={country.code}>{country.name}</option>
                                                        ))}
                                                </select>

                                            </div>
                                            <div className="flex items-center mt-4 mb-5">
                                                <p className="font-bold">Your reference number (optional)</p>
                                                <div className="flex-grow"></div>
                                                <input className="w-1/2 bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                                                    name="BuyersRefNumber" type="text"
                                                    value={renewStore.invoiceRefNumber}
                                                    onChange={(e) => renewStore.setInvoiceRefNumber(e.target.value)} />
                                            </div>
                                        </>
                                    }




                                    {/* buttons */}
                                    <div className="flex pb-3">
                                        <div className="flex-grow"></div>
                                        {renewStore.isCheckoutClicked ?
                                            <>
                                                <div className="flex items-center justify-center mr-4">
                                                    Please wait, redirecting to payment page ...
                                                </div>
                                                <img src={loadingGifSmall} />
                                            </>
                                            :
                                            <>

                                                <div className="flex-col items-center gap-4 mb-4">
                                                    {renewStore.PurchaseError !== '' &&
                                                        <div className="flex text-right">
                                                            <div className="flex-grow"></div>
                                                            <p className="text-red-500 pr-4">{renewStore.PurchaseError}</p>
                                                        </div>

                                                    }
                                                </div>
                                                <button type='button'
                                                    onClick={() => renewStore.HandleCheckoutClicked()}
                                                    className="flex items-center px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
                                                    {/* <!-- SVG for the credit card --> */}
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                        strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                            d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
                                                    </svg>

                                                    &nbsp;&nbsp; Checkout
                                                </button>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    )
});


export default RenewSubscription;