import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RootStoreContext } from '../../stores/rootStore';
import loadingGif from '../../assets/images/Blocks-1s-200px.gif';
import loadingGifSmall from '../../assets/images/Spinner-1s-55px.gif';
import { observer } from "mobx-react-lite";
import { saveVisit } from '../../api/visitTrackerService';



const Purchase = observer(() => {
    const { quantity } = useParams();
    const rootStore = useContext(RootStoreContext);
    const purchaseStore = rootStore.purchaseStore;
    const userStore = rootStore.userStore;

    const { loading } = rootStore;

    const bundle = purchaseStore.bundle;
    const { Qnt, increaseQnt, decreaseQnt } = bundle;

    const {
        iHaveDiscount, isDiscountValid, discountError,
        discountCode, discountPercentageString, codeDiscountString,
        totalDiscountString, showCheckingDiscountSpinner, totalAfterDiscountString,
        isInvoiceNeeded, invoiceBusinessName, invoiceAddress,
        invoiceCountryIsoCode, invoiceRefNumber, countries,
        USDPrice, EURPrice, USDPriceString, EURPriceString,
        isCheckoutClicked, isOrderValid, isInvoiceDataValid } = purchaseStore;

    const { isLoggingIn, isLoggedIn, OpenLoginForm, OpenRegisterForm } = userStore;

    useEffect(() => {
        let numberQuantity
        if (quantity) numberQuantity = Number(quantity);
        else if (Qnt) numberQuantity = Qnt;
        else numberQuantity = 1;
        bundle.setQnt(numberQuantity);
        saveVisit('purchase');
    }, []); // Empty array means this effect runs once on mount and never again

    const handelQntInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        bundle.setQnt(Number(e.target.value));
    }

    const handlePromoInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log(e.target.value);
        purchaseStore.setDiscountCode(e.target.value);
    }

    const [invoiceError, setInvoiceError] = useState<string>('');


    return (
        <div className="xl:px-20 md:px-12 md:mt-5 px-4">
            <div className="text-center">
                {loading ?
                    <>
                        <div className="flex justify-center">
                            <img src={loadingGif} />
                        </div>
                        <p className="text-center"><em>Loading...</em></p>
                    </>
                    :
                    <>

                        <div className="xl:px-20 md:px-12 md:mt-5">
                            <div className="text-center pb-4">
                                <h2 className="text-2xl font-semibold">Order summary</h2>
                                <p className="mt-4 md:mb-6"><strong>Desktop call recorder Pro</strong> is licensed per computer. Please
                                    choose the
                                    required number of licenses. Prices are for annual subscription.</p>
                            </div>

                        </div>

                        <div className="lg:w-6/12 mx-auto">

                            <div className="rounded shadow-lg  ">
                                {/* order form body */}
                                <div className="flex w-full bg-white bg-opacity-100 justify-start px-4 pt-4 pb-4">
                                    <span className="font-semibold text-lg text-left mb-1">DESKTOP CALL RECORDER</span>
                                </div>

                                <div className="flex flex-col bg-white bg-opacity-80 justify-start px-4">
                                    <div className="flex items-center">
                                        <p>PRO subscription</p>
                                        <div className="flex-grow"></div>
                                        <p className="text-right">x 12 months</p>
                                    </div>
                                    {/* <!--qnantity--> */}
                                    <div className="flex mt-4 mb-2 items-center">
                                        <h4 className="">Number of computers</h4>
                                        <div className="flex-grow"></div>
                                        <div className="flex justify-center">
                                            <div className="custom-number-input h-7 w-24 ">
                                                <div className="flex flex-row item h-7 w-full rounded-lg relative mt-1 border border-gray-400 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500">
                                                    <button data-action="decrement" onClick={decreaseQnt}
                                                        className=" bg-gray-200 border-r border-gray-400 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-l cursor-pointer outline-none">
                                                        <span className="m-auto text-lg font-thin">−</span>
                                                    </button>
                                                    <input type="number"
                                                        className="focus:outline-none text-center w-full bg-white font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700  outline-none"
                                                        name="custom-input-number"
                                                        value={Qnt}
                                                        onChange={handelQntInputChange} />
                                                    <button data-action="increment" onClick={increaseQnt}
                                                        className="bg-gray-200 border-l border-gray-400 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-r cursor-pointer">
                                                        <span className="m-auto text-lg font-thin">+</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                    {/* <!--volume discount--> */}
                                    {bundle.Saving > 0 &&
                                        <div className="flex items-center mt-2 mb-3">
                                            <p>Volume Discount Savings</p>
                                            <div className="flex-grow"></div>
                                            <p className="text-red-500">£ {bundle.SavingString}</p>
                                        </div>
                                    }

                                    {/* <!--price annum--> */}
                                    <div className="flex items-center ">
                                        <p>Price per computer per year</p>
                                        <div className="flex-grow text-left pr-3 text-sm pl-3">(£ {bundle.PricePerMonthString} x 12 months)
                                        </div>
                                        <p className=" border-b border-gray-600 ">£ {bundle.PricePerComputerString}</p>
                                    </div>


                                    {/* <!--Promo Code--> */}
                                    {iHaveDiscount ?
                                        <>
                                            {isDiscountValid ?
                                                <>
                                                    <div className="flex items-center mt-2">
                                                        <p>Discount code applied </p>
                                                        <div className="flex-grow"></div>
                                                        <p className="border-gray-500">
                                                            <span className="text-red-500">£ {codeDiscountString} </span>
                                                            ( {discountPercentageString} % )
                                                        </p>
                                                    </div>
                                                    {/* <!--total discount--> */}
                                                    <div className="flex items-center mt-2">
                                                        <p>Total Discount Savings</p>
                                                        <div className="flex-grow"></div>
                                                        <p className="text-red-500">£ {totalDiscountString}</p>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className="flex items-center mt-4">
                                                        <p className="font-semibold">Promo Code</p>
                                                        <div className="flex-grow"></div>
                                                        {showCheckingDiscountSpinner ?

                                                            <div >
                                                                <div className="flex-grow text-right mr-2"> Checking....</div>
                                                                <img src={loadingGifSmall} style={{ width: '24px', height: '24px' }} />
                                                            </div>
                                                            :
                                                            <input
                                                                className="w-24 h-7 bg-white border border-gray-400 text-gray-900 font-semibold text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                                                                value={discountCode}
                                                                onChange={(e) => handlePromoInputChange(e)}
                                                                type="text" />
                                                        }
                                                    </div>
                                                    {/* <!--error message--> */}
                                                    {discountError !== '' &&
                                                        <div className="flex text-right">
                                                            <div className="flex-grow"></div>
                                                            <p className="text-red-500 ">{discountError}</p>
                                                        </div>
                                                    }
                                                </>
                                            }
                                        </>
                                        :
                                        <div className="flex items-center mt-4">
                                            <div className="flex-grow"></div>
                                            <p className="font-semibold mr-6">I have discount code</p>


                                            <div >
                                                <input
                                                    className="justify-center mt-1"
                                                    name="DiscountCodeTick"
                                                    checked={iHaveDiscount}
                                                    onChange={(e) => purchaseStore.setIHaveDiscount(e.target.checked)}
                                                    type="checkbox" />
                                            </div>
                                        </div>
                                    }
                                    {/* <!-- end of discount code--> */}

                                    {/* <!--total after discount--> */}
                                    <div className="flex items-center mt-2">
                                        <p className="font-bold">Total</p>
                                        <div className="flex-grow"></div>
                                        <p className="font-bold border-b border-gray-600 ">£ {totalAfterDiscountString}</p>
                                    </div>
                                    <div className=" border-b border-gray-600 mt-3"></div>
                                    {/* <!--invoice checkbox--> */}
                                    <div className="flex items-center mt-4">
                                        <input type="checkbox" title="I need invoice"
                                            checked={isInvoiceNeeded}
                                            onChange={(e) => purchaseStore.setInvoiceNeeded(e.target.checked)} />
                                        <p className="ml-2">Add your details for invoice (optional)</p>
                                        <div className="flex-grow"></div>
                                        <p className="text-xs text-gray-600">* The amount is shown in £ (Great Britain Pounds GBP)</p>
                                    </div>
                                    {/* <!--usd eur rates--> */}
                                    <div className="flex items-center mb-4">
                                        <div className="flex-grow"></div>
                                        {(USDPrice != 0 && EURPrice != 0) &&

                                            <p className="text-xs text-gray-600">£{totalAfterDiscountString} is aprox.
                                                USD {USDPriceString} or
                                                EUR {EURPriceString}</p>
                                        }
                                    </div>

                                    {/* <!--invoice details--> */}
                                    {isInvoiceNeeded &&
                                        <>
                                            <div className=" border-b border-gray-600 mb-3"></div>
                                            <div className="flex items-center mt-4">
                                                <p className="font-bold">Name/Business name</p>
                                                <div className="flex-grow"></div>
                                                <input className={purchaseStore.invoiceBusinessNameFieldStyle}
                                                    name="Name" type="text"
                                                    value={invoiceBusinessName}
                                                    onChange={(e) => purchaseStore.setInvoiceBusinessName(e.target.value)} />
                                            </div>
                                            <div className="flex items-center mt-4">
                                                <p className="font-bold">Billing address</p>
                                                <div className="flex-grow"></div>
                                                <textarea className={purchaseStore.invoiceAddressFieldStyle}
                                                    cols={50} name="Address" rows={3}
                                                    value={invoiceAddress}
                                                    onChange={(e) => purchaseStore.setInvoiceAddress(e.target.value)} />
                                            </div>
                                            <div className="flex items-center mt-4">
                                                <p className="font-bold">Country</p>
                                                <div className="flex-grow"></div>
                                                <select className={purchaseStore.invoiceCountryIsoCodeFieldStyle}
                                                    value={invoiceCountryIsoCode}
                                                    onChange={(e) => purchaseStore.setInvoiceCountryIsoCode(e.target.value)}>
                                                    {countries &&
                                                        countries.map((country) => (
                                                            <option key={country.code} value={country.code}>{country.name}</option>
                                                        ))}
                                                </select>

                                            </div>
                                            <div className="flex items-center mt-4 mb-5">
                                                <p className="font-bold">Your reference number (optional)</p>
                                                <div className="flex-grow"></div>
                                                <input className="w-1/2 bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                                                    name="BuyersRefNumber" type="text"
                                                    value={invoiceRefNumber}
                                                    onChange={(e) => purchaseStore.setInvoiceRefNumber(e.target.value)} />
                                            </div>
                                        </>
                                    }




                                    {/* buttons */}
                                    {isLoggedIn ?
                                        <div className="flex pb-3">
                                            <div className="flex-grow"></div>
                                            {isCheckoutClicked ?
                                                <>
                                                    <div className="flex items-center justify-center mr-4">
                                                        Please wait, redirecting to payment page ...
                                                    </div>
                                                    <img src={loadingGifSmall} />
                                                </>
                                                :
                                                <>

                                                    <div className="flex-col items-center gap-4 mb-4">
                                                        {purchaseStore.PurchaseError !== '' &&
                                                            <div className="flex text-right">
                                                                <div className="flex-grow"></div>
                                                                <p className="text-red-500 pr-4">{purchaseStore.PurchaseError}</p>
                                                            </div>

                                                        }
                                                    </div>
                                                    <button type='button'
                                                        onClick={() => purchaseStore.HandleCheckoutClicked()}
                                                        className="flex items-center px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
                                                        {/* <!-- SVG for the credit card --> */}
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                            strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                                d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
                                                        </svg>

                                                        &nbsp;&nbsp; Checkout
                                                    </button>
                                                </>
                                            }
                                        </div>

                                        :
                                        <>
                                            {/* Not logged in */}
                                            <span className="font-medium py-3 border-t border-gray-600">
                                                Please sign in or create new account to proceed
                                            </span>
                                            <div className="flex mb-5 justify-center gap-4">
                                                <div className="">
                                                    <button type='button'
                                                        onClick={OpenLoginForm}
                                                        className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-150 ease-in-out hover:bg-blue-600 bg-blue-500 rounded text-white px-8 py-2 text-sm">
                                                        Sign in
                                                    </button>
                                                </div>
                                                <div className="">
                                                    <button type="button"
                                                        onClick={OpenRegisterForm}
                                                        className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-150 ease-in-out hover:bg-blue-600 bg-blue-500 rounded text-white px-8 py-2 text-sm">
                                                        New customer
                                                    </button>
                                                </div>
                                            </div>
                                        </>}
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    );
});



export default Purchase;