import { ICurrentUserDto as ICurrentUser, IForgotInputs, ILoginInputs, IRegisterInputs } from '../models/dtoAuthTypes';
import { RootStore } from './rootStore';
import { observable, action, makeAutoObservable, runInAction, computed } from 'mobx'; // Add missing imports
import { login, logout, getUser, requestResetCall,register } from '../api/authService';



export default class UserStore {
    rootStore: RootStore | undefined;

    //@observable 
    user: ICurrentUser | null = null;
    //@computed 
    get isLoggedIn() {
        return this.user !== null;
    }

    
    //@observable 
    isLoginFormOpened: boolean = false;
    //@observable 
    isForgotFormOpened: boolean = false;
    //@observable 
    isRegisterFormOpened: boolean = false;

    //@observable 
    loginError: string = '';
    //@observable 
    isLoggingIn: boolean = false;

    //@observable 
    forgotError: string = '';
    //@observable 
    registerError: string = '';

    constructor(rootStore: RootStore) {
        rootStore = rootStore;
        makeAutoObservable(this);
        runInAction(() => {
            const storedUser = localStorage.getItem('user');
            if (storedUser) {
                this.user = JSON.parse(storedUser);
            }
        });
        if (this.user !== null) { //if there is stored user, check this by calling the server
            getUser().then((user) => {
                runInAction(() => {
                    this.user = user;
                });
            }).catch((error) => {
                console.error('Failed to get user:', error);
                runInAction(() => {
                    this.user = null;
                });
            }
            );
        }
        
    }

   // @action
    OpenLoginForm = () => {
        this.CloseRegisterForm();
        this.CloseForgotForm();
        this.isLoggingIn = false;
        this.isLoginFormOpened = true;
        this.loginError = '';
    }

    //@action
    CloseLoginForm = () => {
        this.isLoginFormOpened = false;
        this.isLoggingIn = false;
    }

    //@action
    OpenForgotForm = () => {
        this.isLoggingIn = false;
        this.CloseRegisterForm();
        this.CloseLoginForm();
        this.isForgotFormOpened = true;
        this.forgotError = '';
    }

    //@action 
    CloseForgotForm = () => {
        this.isForgotFormOpened = false;
        this.isLoggingIn = false;
    }

    //@action
    OpenRegisterForm = () => {
        this.CloseLoginForm();
        this.CloseForgotForm();
        this.isRegisterFormOpened = true;
        this.registerError = '';
        this.isLoggingIn = false;
    }

    //@action
    CloseRegisterForm = () => {
        this.isRegisterFormOpened = false;
        this.isLoggingIn = false;
    }

    //@action
    Login = async (loginForm: ILoginInputs) => {
        runInAction(() =>
        {
            this.loginError = '';
            this.isLoggingIn = true;
        });

        login(loginForm).then((user) => {
            console.log('Login response:', user?.userName);
            runInAction(() => {
                this.user = user;
                localStorage.setItem('user', JSON.stringify(user));
                this.isLoggingIn = false;
            });
            this.CloseLoginForm();
        }).catch((error) => {
            console.error('Failed to login:', error);
            if (error.response === undefined) {
                this.loginError = error.message;
            }
            else if (error.response.status === 401) {
                this.loginError = 'Invalid username or password';
            }
            else
                this.loginError = error.response.data;
            runInAction(() => {
                this.isLoggingIn = false;
            });
        });
    }

    //@action
    Logout = async () => {
        runInAction(() => {
            this.isLoggingIn = true;
        });
        logout().then((success) => {
            if (success) {
                runInAction(() => {
                    this.user = null
                    this.isLoggingIn = false;
                    localStorage.removeItem('user');
                });
            }
        }).catch((error) => {
            console.error('Failed to logout:', error);
            runInAction(() => {
                this.isLoggingIn = false;
            });
        }
        );
    }

    //@action
    RequestReset = async (forgotForm: IForgotInputs) => {
        runInAction(() => {
            this.forgotError = '';
            this.isLoggingIn = true;
        });
        console.log('Request reset for:', forgotForm.email);
        requestResetCall(forgotForm).then((success) => {
            if (success) {
                runInAction(() => {
                    this.isLoggingIn = false;
                    this.CloseForgotForm();
                });
            }
        }).catch((error) => {
            console.error('Failed to request reset:', error);
            if (error.response === undefined) {
                this.forgotError = error.message;
            }
            else
                this.forgotError = error.response.data;
            runInAction(() => {
                this.isLoggingIn = false;
            });
        });
    }

    //@action
    Register = async (registerForm:IRegisterInputs) => {
        runInAction(() => {
            this.registerError = '';
            this.isLoggingIn = true;
        });
        register(registerForm).then((user) => {
            runInAction(() => {
                this.user = user;
                localStorage.setItem('user', JSON.stringify(user));
                this.isLoggingIn = false;
            });
            this.CloseRegisterForm();
        }).catch((error) => {
            runInAction(() => {
                if (error.response && error.response.data) {
                    this.registerError = error.response.data;
                  }
                  else {
                    this.registerError = error.message;
                  }
                this.isLoggingIn = false;
            });
        });

    }



}
