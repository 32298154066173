export interface PricesList{
    [key:number]:number;
}

export class Prices implements PricesList {
    [key: number]: number;
  
    get isReady() {
      return Object.keys(this).length > 0;
    }
  }

export interface Rates {
    [key:string]:number;
}

export class pricesRatesDto{
    public pricesList:PricesList;
    public rates:Rates;
    constructor(priceList:PricesList, rates:Rates){
        this.pricesList = priceList;
        this.rates = rates;
    }
}

// export interface PurchaseDto{
//     Qnt:number;
//     Name?:string;
//     Address?:string;
//     BuyersRefNumber?:string;
//     CountryIsoCode?:string;
//     InvoiceNeeded:boolean;
//     BuyersVatNumber?:string;
//     DiscountCode?:string;
// }

export interface CheckDiscountCodeResponseDto
{
    isDiscountCodeValid:boolean;
    valitTo:Date;
    discountPercentage:number;
    discountCode:string;
    discountCodeError:string;
}

export interface CountryDto{
    name:string;
    code:string;
    taxRate:number;
}

export interface CountriesDto{  
    detectedCountryCode:string;
    countries:CountryDto[];
}


export interface PurchaseDto {
    Url: string;
    Qnt: number;
    Name?: string;
    Address?: string;
    BuyersRefNumber?: string;
    CountryIsoCode?: string;
    InvoiceNeeded: boolean;
    BuyersVatNumber?: string;
    DiscountCode?: string;
  }

  export interface PaidOrderDto {
    error: string | null;    
    paymentId: number;
    orderId: number;
    subscribtionEndDate: string;
    numberOfSeats: number;
    activationCodes: string[];
  }

  export interface PaymentDtoV2 {
    id: number;
    paymentType: string;
    paymentStatus: string;
    dateTime: string;
    amount: string;
    currency: string;
    invoiceReady: boolean;
    invoiceHtml?: string;
    countryName?: string;
}

export interface OrderDtoV2 {
    id: number;
    orderDate: string;
    qnt: number;
    status: string;
    payment?: PaymentDtoV2;
    isRenewal: boolean;
}

export interface ActivationCodeDto {
    code: string;
    isActivated: boolean;
    computerName: string;
}

export interface SubscriptionDtoV2 {
    id: number;
    productName: string;
    qnt: number;
    endDate: string;
    orders: OrderDtoV2[];
    activationCodes: ActivationCodeDto[];
    isBlocked: boolean;
    isExpired: boolean;
    renewalLink?: string;
}

export interface InvoiceDataDto{
    paymentId:number;
    name:string;
    address:string;
    buyersRefNumber?:string;
    invoiceHtml?:string;
}

export interface InvoiceDetailsFormValues {
    name: string;
    address: string;
    buyersRefNumber: string;
  }

export interface RenewSubscriptionDto {
    subscriptionId: number;
    isNotExpiredYet: boolean;
    pricePerUser: number;
    qnt: number; 
    productName: string;
    endDate: string;
    renewalDiscount: number;
}

export interface RenewPurchaseDto {
    Url: string;
    SubscriptionId: number;
    Name?: string;
    Address?: string;
    BuyersRefNumber?: string;
    CountryIsoCode?: string;
    InvoiceNeeded: boolean;
    BuyersVatNumber?: string;
    DiscountCode?: string;
  }

export interface PaidRenewOrderDto {
    error: string | null;    
    paymentId: number;
    orderId: number;
    subscribtionEndDate: string;
    numberOfSeats: number;
  }