import { createBrowserRouter, Navigate, RouteObject } from "react-router-dom";
import App from "../components/layout/App";
import NotFound from "../components/pages/not-found";
import ServerError from "../components/pages/server-error";
import Purchase from "../components/pages/purchase";
import Subscriptions from "../components/pages/subscriptions";
import PaymentSuccess from "../components/pages/payment-success";
import PaymentCancel from "../components/pages/payment-cancel";
import ResetPassword from "../components/pages/reset-password";
import RenewSubscription from "../components/pages/renew-subscription";
import RenewSuccess from "../components/pages/renew-success";


export const routes: RouteObject[] = [
    {
        path: '/',
        element: <App/>,
        children: [
            {path: 'purchase/:quantity?', element: <Purchase/>},
            {path: 'ResetPassword/:ResetCode', element: <ResetPassword />},
            {path: 'not-found', element: <NotFound />},
            {path: 'server-error', element: <ServerError />},
            {path: '*', element: <Navigate replace to='/not-found' />},
            {path: 'subscriptions', element: <Subscriptions />},
            {path: 'payment/success/:paymentId', element: <PaymentSuccess />},
            {path: 'payment/cancel/:paymentId', element: <PaymentCancel/>},
            {path: 'subscription/renew/:subscriptionCode', element: <RenewSubscription/>},
            {path: 'subscription/renew/a/:activationCodeEncoded', element: <RenewSubscription/>},
            {path: 'renew/success/:paymentId', element: <RenewSuccess />},
            {path: 'renew/cancel/:paymentId', element: <PaymentCancel/>}
            
        ]
    }
]

export const router = createBrowserRouter(routes);